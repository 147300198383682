<template>
    
	<div class="field-zitem blanks_item" type="lc-hblanks" :id="detial.uuid" v-if="!logicFlag || (logicFlag && detial.jumpShow && detial.logicShow)">
        <!-- 单选题 -->
        <div class="field_warp">
            <h5 class="field_title">
                <i v-if="detial.topic_required" class="required">*</i>
                <span class="txt" :style="{fontSize: globalItemStyle.labelStyle.fontSize + 'px',color: globalItemStyle.labelStyle.color,fontWeight: globalItemStyle.labelStyle.fontWeight,fontStyle: globalItemStyle.labelStyle.fontStyle,textDecoration: globalItemStyle.labelStyle.textDecoration}">{{index+1}}.{{detial.topicName}}</span>
            </h5>
            <p class="field_remark" v-if="detial.remarks">{{detial.remarks}}</p>
            <div class="item_contianer">
                <div class="hblank-txt">
                    <span v-for="(item,index) in detial.topicOption" :key="index">
                        <span v-for="(dom,i) in item.domHtml" :key="i">
                            {{dom.name}}<em><el-input v-model="detial.value[i]" :style="'width:'+dom.width"></el-input></em>
                        </span>
                        <!-- <span v-html="item.textHtmlVal"></span> -->
                    </span>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	watch: {
		'globalItemStyle.itemStyle': {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.globalItemStyle.itemStyle;
                    $("#" + this.detial.uuid + ".field-zitem.blanks_item .hblank-txt").css({
                        fontSize:styleStr.fontSize+'px',
                        color: styleStr.color,
                        fontWeight: styleStr.fontWeight,
                        fontStyle:styleStr.fontStyle,
                        textDecoration:styleStr.textDecoration,
                    });
                });
			},
			deep: true,
			immediate: true,
		},
        list:{
            handler(){
                this.$nextTick(()=>{
                    let show = 0;
                    if(this.detial.showLogic && this.detial.showLogic.length > 0){
                        if(this.detial.showLogicCondition == 1){
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    o.value.includes(item.logicOption)&&show++;
                                                }
                                                
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    !(o.value.includes(item.logicOption))&&show++;
                                                }
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show == this.detial.showLogic.length){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }else{
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show > 0){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }
                        
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        detial:{
            handler(){
                this.$nextTick(()=>{
                    if(this.detial.logicJumps && this.detial.logicJumps.length > 0){
                        let sort = 0;
                        this.detial.logicJumps.forEach(item => {
                            this.list.forEach(o=>{
                                if(this.detial.uuid == o.uuid && o.logicShow && o.jumpShow){
                                    let newItem = this.list.filter(c => c.uuid == item.logicTopic)
                                    sort = newItem[0].sort;
                                }
                            })
                        });
                        this.list.forEach(o=>{
                            if(sort){
                                if(o.sort < sort && o.sort > this.detial.sort){
                                    o.jumpShow = false;
                                }else if(o.sort >= sort){
                                    o.jumpShow = true;
                                }
                            }
                        })
                    }
                })
            },
			deep: true,
			immediate: true,
        }
	},
    methods:{
    }
};
</script>
<style lang="less" scoped>
.field-zitem{
    min-height: 190px;
    padding: 0 80px 10px 15px;
    border: 1px solid transparent;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        .input_underline{border:none;border-bottom:1px solid #555;margin-left:5px;}
        .hblank-txt{
            ::v-deep input{
                border:none;border-bottom:1px solid #555;margin-left:5px;background: none;
                border-radius: 0;padding:0;
            }
        }
        .field_blankslist li{
            padding: 3px 0;
            line-height:25px;
            color:#555;
        }
        select.form-control{
            width: 200px;
            margin:0 10px 0 0;
        }
        textarea.form-control{
            height: 130px;
        }
        .inventory_problem{
            .inventory_tips{
                color: #666;
                font-size: 12px;
                float: left;
                height: 30px;
                line-height: 30px;
            }
            .inventory_list{
                margin: 0 5px;
                float: left;
                height: 30px;
                line-height: 30px;
                li{
                    width: 50px;
                    text-align: center;
                } 
            }
        }
        .simulate_table{
            display: table;
            text-align: center;
            border:1px solid #eee;
            .simulate_tr{
                display: flex;
            }
            .simulate_th,.simulate_td{
                display: table-cell;
                flex: 1;
                padding: 5px 0;
                text-align: center;
                border:1px solid #eee;
            }
        }
        .matrix_table{
            width: 100%;
            td,th{
                font-weight: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                border-bottom: 1px solid #ebebeb;
                line-height: 30px;
            }
            td{
                height: 60px;
                line-height: 60px;
            }
        }
        .matrix_inventory{
            th {
                min-width: 80px;
                font-size: 12px;
                color: #666;
            }
        }
        .link_age{
            .linkage_item{
                float: left;
                .linkage_title{
                    min-width: 157px;
                    padding: 3px 5px;
                    margin: 5px 0 0;
                    line-height: 1.8;
                    color: #555;
                }
            }
        }
    }
}


// .question_warp .simulate_tr{border: none;}
// .question_warp .simulate_th,.question_warp .simulate_td{padding: 10px 0;}
// .linkage_list{max-height: 250px;border: 1px solid #ddd;}
// .linkage_list li{padding: 0 35px 0 5px;border: 1px solid transparent;line-height: 2;background: #fff;position: relative;}
// .linkage_list li:after{content: "";display: none;width: 0;height: 0;border-width: 5px 8px;border-style: solid;border-color: transparent transparent transparent #aaa;position: absolute;top: 10px;right: 0;}
// .linkage_list li.has_next:after{display: block;}
// .linkage_list li:hover{background: #f5f5f5;}
.field-zitem.text_item{
    min-height:70px;
    line-height: 36px;
}
.field-zitem.hr{
    min-height: 0;
    line-height: 36px;
}

</style>