<template>
	<mQuesSingleText v-if="item.modelType == 'single_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesMultiRowText v-else-if="item.modelType == 'multiRow_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesRadio v-else-if="item.modelType == 'radio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesChecked v-else-if="item.modelType == 'checked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesSelect v-else-if="item.modelType == 'select'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesScale v-else-if="item.modelType == 'scale'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesDate v-else-if="item.modelType == 'date'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesMatrixRadio v-else-if="item.modelType == 'matrix_radio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesMatrixChecked v-else-if="item.modelType == 'matrix_checked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesMatrixScale v-else-if="item.modelType == 'matrix_scale'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesGanged v-else-if="item.modelType == 'ganged'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesText v-else-if="item.modelType == 'text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesImagesRadio v-else-if="item.modelType == 'imagesRadio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesImagesChecked v-else-if="item.modelType == 'imagesChecked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesHr v-else-if="item.modelType == 'hr'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesLcLocation v-else-if="item.modelType == 'lc-location'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesLcBlanks v-else-if="item.modelType == 'lc-blanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesLcHblanks v-else-if="item.modelType == 'lc-hblanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesLcTable02 v-else-if="item.modelType == 'lc-table02'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<mQuesLcClassify v-else-if="item.modelType == 'lc-classify'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
</template>
<script>
import mQuesChecked from "@/components/quesWidgetM/mQuesChecked";
import mQuesDate from "@/components/quesWidgetM/mQuesDate";
import mQuesRadio from "@/components/quesWidgetM/mQuesRadio";
import mQuesGanged from "@/components/quesWidgetM/mQuesGanged";
import mQuesHr from "@/components/quesWidgetM/mQuesHr";
import mQuesImagesChecked from "@/components/quesWidgetM/mQuesImagesChecked";
import mQuesImagesRadio from "@/components/quesWidgetM/mQuesImagesRadio";
import mQuesLcBlanks from "@/components/quesWidgetM/mQuesLcBlanks";
import mQuesLcClassify from "@/components/quesWidgetM/mQuesLcClassify";
import mQuesLcHblanks from "@/components/quesWidgetM/mQuesLcHblanks";
import mQuesLcLocation from "@/components/quesWidgetM/mQuesLcLocation";
import mQuesLcTable02 from "@/components/quesWidgetM/mQuesLcTable02";
import mQuesMatrixChecked from "@/components/quesWidgetM/mQuesMatrixChecked";
import mQuesMatrixRadio from "@/components/quesWidgetM/mQuesMatrixRadio";
import mQuesMatrixScale from "@/components/quesWidgetM/mQuesMatrixScale";
import mQuesMultiRowText from "@/components/quesWidgetM/mQuesMultiRowText";
import mQuesScale from "@/components/quesWidgetM/mQuesScale";
import mQuesSelect from "@/components/quesWidgetM/mQuesSelect";
import mQuesSingleText from "@/components/quesWidgetM/mQuesSingleText";
import mQuesText from "@/components/quesWidgetM/mQuesText";
export default {
	components: {
		mQuesChecked,
		mQuesDate,
		mQuesRadio,
		mQuesGanged,
		mQuesHr,
		mQuesImagesChecked,
		mQuesImagesRadio,
		mQuesLcBlanks,
		mQuesLcClassify,
		mQuesLcLocation,
		mQuesLcTable02,
		mQuesLcHblanks,
		mQuesMatrixChecked,
		mQuesMatrixRadio,
		mQuesMatrixScale,
		mQuesMultiRowText,
		mQuesScale,
		mQuesSelect,
		mQuesSingleText,
		mQuesText,
	},
	props: {
		item: {
			type: Object,
		},
		globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
};
</script>
<style lang="less">

</style>