<template>
    
	<div class="field-mitem hr special_item" type="hr" :id="detial.uuid">
        <!-- 单选题 -->
        <el-divider></el-divider>
	</div>
</template>
<script>
//import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
    methods:{
    }
};
</script>
<style lang="less" scoped>
.field-mitem{
    padding: 0 10px 10px;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
}

.field-mitem.hr{
    min-height: 0;
    line-height: 36px;
    padding-bottom: 0;
}

</style>