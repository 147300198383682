<template>
    
	<div class="field-zitem link_age" type="ganged" :id="detial.uuid" v-if="!logicFlag || (logicFlag && detial.jumpShow && detial.logicShow)">
        <!-- 单选题 -->
        <div class="field_warp">
            <h5 class="field_title">
                <i v-if="detial.topic_required" class="required">*</i>
                <span class="txt" :style="{fontSize: globalItemStyle.labelStyle.fontSize + 'px',color: globalItemStyle.labelStyle.color,fontWeight: globalItemStyle.labelStyle.fontWeight,fontStyle: globalItemStyle.labelStyle.fontStyle,textDecoration: globalItemStyle.labelStyle.textDecoration}">{{index+1}}.{{detial.topicName}}</span>
            </h5>
            <p class="field_remark" v-if="detial.remarks">{{detial.remarks}}</p>
            <div class="item_contianer">
                <div class="linkage_item" v-if="detial.gangedName.length>0">
                    <el-select class="linkage_title" v-model="detial.value[0]"  :placeholder="detial.gangedName[0]" @change="changeGanged1">
                        <el-option :value="detial.gangedName[0]" :label="detial.gangedName[0]"></el-option>	
                        <template>
                            <el-option v-for="(option,index) in detial.gangedArray" :key="index" :value="option.kayName" :label="option.kayVal"></el-option>
                        </template>			
                    </el-select>
                </div>
                <div class="linkage_item" v-if="detial.gangedName.length>1">
                    <el-select class="linkage_title" v-model="detial.value[1]" :placeholder="detial.gangedName[1]" @change="changeGanged2">
                        <el-option :value="detial.gangedName[1]" :label="detial.gangedName[1]"></el-option>	
                        <template v-if="gangedArray2 && gangedArray2.length>0">
                            <el-option v-for="(option,index) in gangedArray2" :key="index" :value="option.kayName" :label="option.kayVal"></el-option>	
                        </template>		
                    </el-select>
                </div>
                <div class="linkage_item" v-if="detial.gangedName.length>2">
                    <el-select class="linkage_title" v-model="detial.value[2]" :placeholder="detial.gangedName[2]">
                        <el-option :value="detial.gangedName[2]" :label="detial.gangedName[2]"></el-option>	
                        <template v-if="gangedArray3 && gangedArray3.length>0">
                            <el-option v-for="(option,index) in gangedArray3" :key="index" :value="option.kayName" :label="option.kayVal"></el-option>	
                        </template>			
                    </el-select>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
    data(){
        return {
            gangedArray2:[],
            gangedArray3:[],
        }
    },
	watch: {
		'globalItemStyle.itemStyle': {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.globalItemStyle.itemStyle;
                    $("#" + this.detial.uuid + ".field-zitem.link_age .el-input__inner,#" + this.detial.uuid + ".field-zitem.link_age .el-select-dropdown__item").css({
                        fontSize:styleStr.fontSize+'px',
                        color: styleStr.color,
                        fontWeight: styleStr.fontWeight,
                        fontStyle:styleStr.fontStyle,
                        textDecoration:styleStr.textDecoration,
                    });
                });
			},
			deep: true,
			immediate: true,
		},
        list:{
            handler(){
                this.$nextTick(()=>{
                    let show = 0;
                    if(this.detial.showLogic && this.detial.showLogic.length > 0){
                        if(this.detial.showLogicCondition == 1){
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    o.value.includes(item.logicOption)&&show++;
                                                }
                                                
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    !(o.value.includes(item.logicOption))&&show++;
                                                }
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show == this.detial.showLogic.length){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }else{
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show > 0){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }
                        
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        detial:{
            handler(){
                this.$nextTick(()=>{
                    if(this.detial.logicJumps && this.detial.logicJumps.length > 0){
                        let sort = 0;
                        this.detial.logicJumps.forEach(item => {
                            this.list.forEach(o=>{
                                if(this.detial.uuid == o.uuid && o.logicShow && o.jumpShow){
                                    let newItem = this.list.filter(c => c.uuid == item.logicTopic)
                                    sort = newItem[0].sort;
                                }
                            })
                        });
                        this.list.forEach(o=>{
                            if(sort){
                                if(o.sort < sort && o.sort > this.detial.sort){
                                    o.jumpShow = false;
                                }else if(o.sort >= sort){
                                    o.jumpShow = true;
                                }
                            }
                        })
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        'detial.value':{
           handler() {
                this.$nextTick(function () {
                    if(this.detial.value[0]){
                        this.detial.gangedArray.forEach(item=>{
                            if(item.kayName == this.detial.value[0]){
                                this.gangedArray2 = item.kayArray;
                            }
                        })
                    }
                    if(this.detial.value[1]){
                        this.gangedArray2.forEach(item=>{
                            if(item.kayName == this.detial.value[1]){
                                this.gangedArray3 = item.kayArray;
                            }
                        })
                    }
                });
			},
			deep: true,
			immediate: true, 
        }
	},
    methods:{
        changeGanged1(val){
            if(val == this.detial.gangedName[0]){
				this.gangedArray2 = [];
                this.gangedArray3 = [];
                this.detial.value[1] = '';
                this.detial.value[2] = ''
            }else{
                this.detial.gangedArray.forEach(item=>{
                    if(item.kayName == val){
                        this.gangedArray2 = item.kayArray;
                    }
                })
            }
        },
        changeGanged2(val){
            if(val == this.detial.gangedName[1]){
                this.gangedArray3 = [];
                this.detial.value[2] = ''
            }else{
                this.gangedArray2.forEach(item=>{
                    if(item.kayName == val){
                        this.gangedArray3 = item.kayArray;
                    }
                })
            }
        },
    }
};
</script>
<style lang="less" scoped>
.field-zitem{
    min-height: 190px;
    padding: 0 80px 10px 15px;
    border: 1px solid transparent;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        .field_list{
            .el-radio{
                padding: 3px 0;
                line-height:25px;
                color:#555;
            }
        }
        .field_blankslist li{
            padding: 3px 0;
            line-height:25px;
            color:#555;
        }
        select.form-control{
            width: 200px;
            margin:0 10px 0 0;
        }
        textarea.form-control{
            height: 130px;
        }
    }
}

.link_age{
    .linkage_item{
        float: left;
        .linkage_title{
            min-width: 157px;
            padding: 3px 5px;
            margin: 5px 0 0;
            line-height: 1.8;
            color: #555;
        }
    }
}

</style>