<template>
	<quesSingleText v-if="item.modelType == 'single_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesMultiRowText v-else-if="item.modelType == 'multiRow_text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesRadio v-else-if="item.modelType == 'radio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesChecked v-else-if="item.modelType == 'checked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesSelect v-else-if="item.modelType == 'select'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesScale v-else-if="item.modelType == 'scale'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesDate v-else-if="item.modelType == 'date'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list" />
	<quesMatrixRadio v-else-if="item.modelType == 'matrix_radio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesMatrixChecked v-else-if="item.modelType == 'matrix_checked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesMatrixScale v-else-if="item.modelType == 'matrix_scale'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesGanged v-else-if="item.modelType == 'ganged'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesText v-else-if="item.modelType == 'text'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesImagesRadio v-else-if="item.modelType == 'imagesRadio'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesImagesChecked v-else-if="item.modelType == 'imagesChecked'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesHr v-else-if="item.modelType == 'hr'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesLcLocation v-else-if="item.modelType == 'lc-location'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesLcBlanks v-else-if="item.modelType == 'lc-blanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesLcHblanks v-else-if="item.modelType == 'lc-hblanks'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesLcTable02 v-else-if="item.modelType == 'lc-table02'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
	<quesLcClassify v-else-if="item.modelType == 'lc-classify'" :index="item.sort" :detial="item" :globalItemStyle="globalItemStyle" :logicFlag="logicFlag" :list="list"/>
</template>
<script>
import quesChecked from "@/components/quesWidget/quesChecked";
import quesDate from "@/components/quesWidget/quesDate";
import quesRadio from "@/components/quesWidget/quesRadio";
import quesGanged from "@/components/quesWidget/quesGanged";
import quesHr from "@/components/quesWidget/quesHr";
import quesImagesChecked from "@/components/quesWidget/quesImagesChecked";
import quesImagesRadio from "@/components/quesWidget/quesImagesRadio";
import quesLcBlanks from "@/components/quesWidget/quesLcBlanks";
import quesLcClassify from "@/components/quesWidget/quesLcClassify";
import quesLcHblanks from "@/components/quesWidget/quesLcHblanks";
import quesLcLocation from "@/components/quesWidget/quesLcLocation";
import quesLcTable02 from "@/components/quesWidget/quesLcTable02";
import quesMatrixChecked from "@/components/quesWidget/quesMatrixChecked";
import quesMatrixRadio from "@/components/quesWidget/quesMatrixRadio";
import quesMatrixScale from "@/components/quesWidget/quesMatrixScale";
import quesMultiRowText from "@/components/quesWidget/quesMultiRowText";
import quesScale from "@/components/quesWidget/quesScale";
import quesSelect from "@/components/quesWidget/quesSelect";
import quesSingleText from "@/components/quesWidget/quesSingleText";
import quesText from "@/components/quesWidget/quesText";
export default {
	components: {
		quesChecked,
		quesDate,
		quesRadio,
		quesGanged,
		quesHr,
		quesImagesChecked,
		quesImagesRadio,
		quesLcBlanks,
		quesLcClassify,
		quesLcLocation,
		quesLcTable02,
		quesLcHblanks,
		quesMatrixChecked,
		quesMatrixRadio,
		quesMatrixScale,
		quesMultiRowText,
		quesScale,
		quesSelect,
		quesSingleText,
		quesText,
	},
	props: {
		item: {
			type: Object,
		},
		globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	methods:{
		
	}
};
</script>
<style lang="less">

</style>