<template>
    <div class="field-item" type="topic_add_edit">
	<table class="form-group">
		<tbody>
			<tr id="formTitle" v-if="detial.modelType != 'text'">
				<td width="50" class="c-grouptext">
					<span>题目</span>
				</td>
				<td class="control-panel bold_warp">
					<!-- <p id="topic" class="form-control text_edit" contenteditable="true">{{detial.topicName}}</p> -->
					<el-input id="topic" class="form-control text_edit" v-model="detial.topicName"></el-input>
					<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
					<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
				</td>
			</tr>
			<tr id="formRemark" v-if="detial.modelType != 'text'">
				<td width="50" class="c-grouptext">
					<span>备注</span>
				</td>
				<td class="control-panel bold_warp">
					<!-- <p id="remarks" class="form-control text_edit" contenteditable="true">{{detial.remarks}}</p> -->
					<el-input id="remarks" class="form-control text_edit" v-model="detial.remarks"></el-input>
					<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
					<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
				</td>
			</tr>
			<tr id="formType" v-if="currentType != 'text'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<el-select class="form_select" v-model="currentType" placeholder="请选择" disabled v-if="detial.parentType == 'personal'">
						<el-option :value="item.modelType" :label="item.modelTypeName" v-for="item in widgetList" :key="item._id"></el-option>
					</el-select>
					<el-select class="form_select" v-model="currentType" placeholder="请选择" @change="modelTypeUpdate" v-else>
						<el-option :value="item.modelType" :label="item.modelTypeName" v-for="item in widgetList" :key="item._id"></el-option>
					</el-select>
					<el-checkbox class="checkbox-text" id="topic_required" v-model="detial.topic_required">必填</el-checkbox>
					<input type="hidden" id="confirmType">
					<span class="area-tip" v-if="detial.modelType == 'lc-location'">注：详细省市列表可在保存后到前台查看，后台不支持显示。</span>
				</td>
			</tr>
			<!-- 单行文本题、多行文本题不显示 -->
			<tr id="formStyle0" v-if="detial.modelType == 'single_text'||detial.modelType == 'multiRow_text'||detial.modelType == 'date'||detial.modelType == 'lc-location'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 单选题、下拉题、多选题,formStyle1显示 -->
			<tr id="formStyle1" v-if="detial.modelType == 'radio'||detial.modelType == 'select'||detial.modelType == 'checked'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="topic_option">
						<table class="form_table" name="normal_table">
							<tr v-for="(item,index) in detial.topicOption" :key="index" :label="index">
								<td v-if="item.rests != 1">
									<div class="form_item bold_warp">
										<el-input class="form-control text_edit" name="topic_option" placeholder="选项" v-model="item.textVal"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index);">×</a>
									</div>
								</td>
							</tr>
						</table>
						<table class="form_table" name="rests_table" v-if="detial.modelType == 'radio'||detial.modelType == 'checked'">
							<tr v-for="(item,index) in detial.topicOption" :key="index" :label="index">
								<td v-if="item.rests == 1">
									<div class="form_item bold_warp">
										<el-input class="form-control text_edit" name="topic_option" v-model="item.textVal" @blur="changeRests"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index,'rests_table');">×</a>
									</div>
								</td>
							</tr>
						</table>
						<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addTopicOption">新建选项</a>
						</div>
						<div class="add_other" v-if="detial.modelType == 'radio'||detial.modelType == 'checked'">
							<a href="javascript: void(0);" name="rests_a" class="font-gray" v-if="isRests">添加其他</a>
							<a href="javascript: void(0);" name="rests_a" @click="addTopicOption(this,'rests_table');" v-else>添加其他</a>
						</div>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
			<!-- 量表题,formStyle2显示 --> 
			<tr id="formStyle2" v-if="detial.modelType == 'scale'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="inventory_problem">
						<span class="inventory_item">
							<label>量表类型</label>
							<el-select class="form_select" v-model="detial.scale_type" placeholder="请选择">
								<el-option value="满意" label="满意度"></el-option>
								<el-option value="认同" label="认同度"></el-option>
								<el-option value="重要" label="重要度"></el-option>
								<el-option value="愿意" label="愿意度"></el-option>
								<el-option value="符合" label="符合度"></el-option>
								<el-option value="自定义" label="自定义"></el-option>
							</el-select>
						</span>
						<span class="inventory_item scale_type" v-if="detial.scale_type == '自定义'">
							<label>类型列表</label>
							<el-input class="form-control text_edit" v-model="detial.scale_type_start" maxlength="10"></el-input>
							-
							<el-input class="form-control text_edit" v-model="detial.scale_type_end" maxlength="10"></el-input>
						</span>
						<span class="inventory_item">
							<label>量表范围</label>
							<el-input class="form-control text_edit" type="number" v-model="detial.scale_scope" maxlength="8"></el-input> 
						</span>
						<span class="inventory_item">
							<label>起始数值 </label>
							<el-input class="form-control text_edit" type="number" v-model="detial.scale_start" maxlength="8"></el-input>
						</span>
					</div>
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 矩阵单选题、矩阵多选题,formStyle3显示 --> 
			<tr id="formStyle3" v-if="detial.modelType == 'matrix_radio'||detial.modelType == 'matrix_checked'">
				<td class="control-panel" colspan="2">
					<div class="matrix_single">
						<div class="matrix_sitem">
							<h5 class="matrix_stitle">问题管理</h5>
							<table class="form_table topic">
								<tr v-for="(item,index) in detial.matrixTopic" :key="index">
									<td>
										<div class="form_item bold_warp">
											<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="item.textVal"></el-input>
											<!-- <p class="form-control text_edit" contenteditable="true" name="topic_option" title="问题" @click="labelHide(this)" onblur="labelShow(this)"><span class="place_holder">问题</span></p> -->
											<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
											<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
											<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index,'问题');">×</a>
										</div>
									</td>
								</tr>
							</table>
							<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addMatrixOption('问题');">新建问题</a>
							</div>
						</div>
						<div class="matrix_sitem">
							<h5 class="matrix_stitle">选项管理</h5>
							<table class="form_table option">
								<tr v-for="(item,index) in detial.matrixOption" :key="index">
									<td>
										<div class="form_item bold_warp">
											<el-input class="form-control text_edit" name="topic_option" placeholder="选项" v-model="item.textVal"></el-input>
											<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
											<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
											<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index,'选项');">×</a>
										</div>
									</td>
								</tr>
							</table>
							<div class="add_option_btn">
								<a href="javascript: void(0);" @click="addMatrixOption('选项');">新建选项</a>
							</div>
						</div>
					</div>
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 矩阵量表题,formStyle4显示 --> 
			<tr id="formStyle4" v-if="detial.modelType == 'matrix_scale'">
				<td class="control-panel" colspan="2">
					<div class="matrix_single">
						<div class="matrix_sitem">
							<h5 class="matrix_stitle">问题管理</h5>
							<table class="form_table topic">
								<tr v-for="(item,index) in detial.matrixTopic" :key="index">
									<td>
										<div class="form_item bold_warp">
											<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="item.textVal"></el-input>
											<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
											<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
											<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index,'问题');">×</a>
										</div>
									</td>
								</tr>
							</table>
							<div class="add_option_btn">
								<a href="javascript: void(0);" @click="addMatrixOption('问题');">新建问题</a>
							</div>
						</div>
						<div class="matrix_sitem">
							<h5 class="matrix_stitle">量表管理</h5>
							<table class="form_table">
								<tr>
									<td>
										<div class="form_item">
											<span class="inventory_item">
												<label>量表类型</label>
												<el-select class="form_select" v-model="detial.scale_type" placeholder="请选择">
													<el-option value="满意" label="满意度"></el-option>
													<el-option value="认同" label="认同度"></el-option>
													<el-option value="重要" label="重要度"></el-option>
													<el-option value="愿意" label="愿意度"></el-option>
													<el-option value="符合" label="符合度"></el-option>
													<el-option value="自定义" label="自定义"></el-option>
												</el-select>
											</span>
										</div>
									</td>
								</tr>
								<tr class="scale_type" v-if="detial.scale_type == '自定义'">
									<td>
										<div class="form_item">
											<span class="inventory_item">
												<label>类型列表</label>
												<el-input class="form-control" type="text" v-model="detial.scale_type_start" maxlength="10"></el-input>
												-
												<el-input class="form-control" type="text" v-model="detial.scale_type_end" maxlength="10"></el-input> 
											</span>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="form_item">
											<span class="inventory_item">
												<label>量表范围</label>
												<el-input class="form-control" type="number" v-model="detial.scale_scope"></el-input>
											</span>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div class="form_item">
											<span class="inventory_item">
												<label>起始数值</label>
												<el-input class="form-control" type="number" v-model="detial.scale_start"></el-input>
											</span>
										</div>
									</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 联动题,formStyle5显示 -->
			<tr id="formStyle5" v-if="detial.modelType == 'ganged'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="cl">
						<div class="linkage_warp">
							<div class="linkage_item" v-if="detial.gangedName.length>0">
								<div class="linkage_top">
									<el-input class="form-control" v-model="detial.gangedName[0]" placeholder="添加1级标题，如省"></el-input>
									<a class="deleteOption" href="javascript: void(0);" @click="deleteTitle(0)">×</a>
								</div>
								<div class="linkage_option">
									<ul class="option_list">
										<li class="option_item" :class="{active:detial.curOpt1 === index}" v-for="(option,index) in detial.gangedArray" :key="'first'+index">
											<el-input class="form-control" autofocus="true" @blur="loseFocus(index,1)" @focus="acquireFocus(index,1);" v-model="option.kayVal" :ref="'first'+index"></el-input>
											<a href="javascript: void(0);" @click="deleteGangedOption(index,1)">×</a>
										</li>
									</ul>
									<a class="addlink_option" href="javascript: void(0);" @click="addGangedOption(1);">新建选项</a>
								</div>
							</div>
							<div class="linkage_item" v-if="detial.gangedName.length>1">
								<div class="linkage_top">
									<el-input class="form-control" v-model="detial.gangedName[1]" placeholder="添加2级标题，如市"></el-input>
									<a class="deleteOption" href="javascript: void(0);" @click="deleteTitle(1)">×</a>
								</div>
								<div class="linkage_option" v-if="detial.curOpt1 !== ''">
									<ul class="option_list" v-if="detial.gangedArray[detial.curOpt1].kayArray.length>0">
										<li class="option_item" :class="{active:detial.curOpt2 === index}" v-for="(option,index) in detial.gangedArray[detial.curOpt1].kayArray" :key="'second'+index">
											<el-input class="form-control" autofocus="true" @blur="loseFocus(index,2)" @focus="acquireFocus(index,2);" v-model="option.kayVal" :ref="'second'+index"></el-input>
											<a href="javascript: void(0);" @click="deleteGangedOption(index,2)">×</a>
										</li>
									</ul>
									<a class="addlink_option" href="javascript: void(0);" @click="addGangedOption(2);">新建选项</a>
								</div>
								<p class="linkage_tips" v-else>请选择上一级选项 </p>
							</div>
							<div class="linkage_item" v-if="detial.gangedName.length>2">
								<div class="linkage_top">
									<el-input class="form-control" v-model="detial.gangedName[2]" placeholder="添加3级标题，如区"></el-input>
									<a class="deleteOption" href="javascript: void(0);" @click="deleteTitle(2)">×</a>
								</div>
								<div class="linkage_option" v-if="detial.curOpt2 !== ''">
									<ul class="option_list" v-if="detial.gangedArray[detial.curOpt1].kayArray[detial.curOpt2].kayArray.length>0">
										<li class="option_item" :class="{active:detial.curOpt3 === index}" v-for="(option,index) in detial.gangedArray[detial.curOpt1].kayArray[detial.curOpt2].kayArray" :key="'third'+index">
											<el-input class="form-control" autofocus="true" type="text" @blur="loseFocus(index,3)" @focus="acquireFocus(index,3);" v-model="option.kayVal" :ref="'third'+index"></el-input>
											<a href="javascript: void(0);" @click="deleteGangedOption(index,3)">×</a>
										</li>
									</ul>
									<a class="addlink_option" href="javascript: void(0);" @click="addGangedOption(3);">新建选项</a>
								</div>
								<p class="linkage_tips" v-else>请选择上一级选项 </p>
							</div>
						</div>
						<div class="linkage_btn ganged_btn" v-if="detial.gangedName.length<3">
							<el-button class="form-cancel" @click="addTitle">添加标题</el-button>
						</div>
					</div>
					<div class="bottom_btn ">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 文本描述,formStyle6显示 -->
			<tr id="formStyle6" v-if="detial.modelType == 'text'">
				<td class="control-panel" colspan="2">
					<div class="topic_option">
						<table class="form_table" name="normal_table">
							<tr>
								<td>
									<div class="form_item bold_warp">
										<el-input class="form-control" placeholder="文本描述" v-model="detial.textInput"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
									</div>
								</td>
							</tr>
						</table>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
			<!-- 图片选择,formStyle7显示 -->
			<tr id="formStyle7" v-if="detial.modelType == 'images'||detial.modelType == 'imagesRadio'||detial.modelType == 'imagesChecked'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel bold_warp">
					<ul class="showimg_list">
						<li class="showimg_item" v-for="(item,index) in detial.topicOption" :key="index">
							<div class="showimg_box">
								<img :src="item.imgSrc">
								<div class="showimg_opbtn">
									<!-- <a href="javascript: void(0);" @click="editImgShape(index);">编辑</a> -->
									<a href="javascript: void(0);" @click="handleRemove(index);">删除</a>
								</div>
							</div>
							<div class="showimg_name">
								<el-radio :label="item.textVal" v-model="detial.value" disabled v-if="detial.modelType == 'imagesRadio'">
									<el-input class="ques_input" placeholder="名称" v-model="item.textVal"></el-input>
								</el-radio>
								<el-checkbox :label="item.textVal" v-model="detial.value" disabled v-if="detial.modelType == 'imagesChecked'">
									<el-input class="ques_input" placeholder="名称" v-model="item.textVal"></el-input>
								</el-checkbox>
							</div>
						</li>
						<li class="showimg_addbtn">
							<el-upload class="avatar-uploader" ref="pictureUpload" :show-file-list="false" multiple action="/com/uplaod_modelimg.do" :file-list="detial.topicOption" :headers="{Authorization: 'Form Bearer ' + formToken}" accept="image/*" list-type="picture-card" :before-upload="beforeImgUpload" :on-success="handleImgSuccess">
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<span class="avatar-txt">请选择图片</span>
							</el-upload>
						</li>
					</ul>					
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 多选填空,formStyle8显示 -->
			<tr id="formStyle8" v-if="detial.modelType == 'lc-blanks'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="topic_option">
						<table class="form_table topic" name="normal_table">
							<tr v-for="(item,index) in detial.topicOption" :key="index">
								<td>
									<div class="form_item bold_warp">
										<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="item.textVal"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index);">×</a>
									</div>
								</td>
							</tr>
						</table>
						<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addMatrixOption('blanks');">新建问题</a>
						</div>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
			<!-- 横向填空,formStyle9显示 -->
			<tr id="formStyle9" v-if="detial.modelType == 'lc-hblanks'">
				<td width="50" class="c-grouptext"></td>
				<td class="control-panel">
					<div class="topic_option">
						<table class="form_table" name="normal_table">
							<tr v-for="(item,index) in detial.topicOption" :key="index">
								<td>
									<div class="form_item bold_warp hblank-txt">
										<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="item.textVal" @blur="changeValue"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
										<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
										<a class="set_blank" href="javascript: void(0);" @click="addtxtface(this,event);">...</a>
										<!-- <a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(this);">×</a> -->
										<div class="hide_val"></div>
									</div>
								</td>
							</tr>
						</table>
						<div class="bottom_btn">
							<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
							<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
						</div>
					</div>
				</td>
			</tr>
			<!-- 自增表格,formStyle10显示 --> 
			<tr id="formStyle10" v-if="detial.modelType == 'lc-table02'">
				<td class="control-panel" colspan="2">
					<div class="matrix_table02">
						<h5 class="matrix_stitle">问题管理</h5>
						<table class="form_table">
							<tr v-for="(option,index) in detial.topicOption" :key="index">
								<td>
									<div class="form_item bold_warp">
										<el-input class="form-control text_edit" name="topic_option" placeholder="问题" v-model="option.textVal"></el-input>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
									</div>
								</td>
								<td>
									<div class="form_item bold_warp">
										<el-select v-model="option.textType" class="form_select" onchange="selTopicType(this)">
											<el-option value="blanks" label="填空"></el-option>
											<el-option value="select" label="下拉选择"></el-option>
										</el-select>
										<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
									</div>
								</td>
								<td>
									<div class="form_item bold_warp" v-if="option.textType == 'select'">
										<el-input class="form-control text_edit" title="选项;选项" placeholder="选项;选项" v-model="option.textOption"></el-input>
									</div>
								</td>
								<td>
									<div class="form_item bold_warp">
										<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index);">×</a>
									</div>
								</td>
							</tr>
						</table>
						<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addMatrixOption('table');">新建问题</a>
						</div>
					</div>
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
			</tr>
			<!-- 分类题,formStyle11显示 --> 
			<tr id="formStyle11" v-if="detial.modelType == 'lc-classify'">
				<td class="control-panel" colspan="2">
					<div class="matrix_single">
						<div class="matrix_sitem">
							<h5 class="matrix_stitle">选项管理</h5>
							<table class="form_table topic">
								<tr v-for="(option,index) in detial.matrixOption" :key="index">
									<td>
										<div class="form_item bold_warp">
											<el-input class="form-control" placeholder="选项" v-model="option.textVal"></el-input>
											<!-- <p class="form-control text_edit" contenteditable="true" name="topic_option" title="选项" @click="labelHide(this)" onblur="labelShow(this)"><span class="place_holder">选项</span></p> -->
											<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
											<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
											<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index,'选项');">×</a>
										</div>
									</td>
								</tr>
							</table>
							<div class="add_option_btn">
							<a href="javascript: void(0);" @click="addMatrixOption('选项')">新建选项</a>
							</div>
						</div>
						<div class="matrix_sitem">
							<h5 class="matrix_stitle">类别管理</h5>
							<table class="form_table option">
								<tr v-for="(option,index) in detial.matrixTopic" :key="index">
									<td>
										<div class="form_item bold_warp">
											<el-input class="form-control" placeholder="类别" v-model="option.textVal"></el-input>
											<a class="set_bold" href="javascript: void(0);" @click="boldface(this,event);">B</a>
											<a class="upload_img" href="javascript: void(0);" @click="uploadImg(this,event);"></a>
											<a class="deleteOption" href="javascript: void(0);" @click="deleteTopicOption(index,'classify');">×</a>
										</div>
									</td>
								</tr>
							</table>
							<div class="add_option_btn">
								<a href="javascript: void(0);" @click="addMatrixOption('classify');">新建类别</a>
							</div>
						</div>
					</div>
					<div class="bottom_btn">
						<el-button type="primary" class="form-save" @click="confirmAdd(this);">确定</el-button>
						<el-button class="form-cancel" @click="closeTopicAddEdit(this);">取消</el-button>
					</div>
				</td>
		</tr>
		</tbody>
	</table>
</div>
</template>
<script>
//import $ from "jquery";
export default {
	props: {
		detail: {
			type: Object,
		},
		widgetlist:{
			type: Array,
		}
	},
	emits:['confirm-add','close-add'],
	data(){
		return {
			isRests:false,
			formToken: sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			detial:JSON.parse(JSON.stringify(this.detail)),
			currentType:this.detail.modelType,
			widgetList:JSON.parse(JSON.stringify(this.widgetlist)),
		}
	},
	created(){
		this.getRests()
	},
    methods:{
		// 选择题目类型
		modelTypeUpdate(e){
			let sort = this.detial.sort
			this.widgetList.forEach(widget=>{
				if(widget.modelType == e){
					this.detial = widget;
					this.detial.editState = true;
					this.detial.sort = sort;
					this.getRests()
					// if(e == 'select'||e == 'radio'||e == 'checked'||e == 'imagesRadio'||e == 'imagesChecked'||e == 'lc-blanks'||e == 'lc-table02'){
					// 	this.detial.topicOption.forEach((option,index)=>{
					// 		option['textKey'] = 'ques'+new Date().getTime()+index;
					// 	})
					// }else if(e == 'matrix_radio'||e == 'matrix_checked'||e == 'lc-classify'){
					// 	this.detial.matrixTopic.forEach((option,index)=>{
					// 		option['textKey'] = 'ques'+new Date().getTime()+index;
					// 	})
					// 	this.detial.matrixOption.forEach((option,index)=>{
					// 		option['textKey'] = 'ques'+new Date().getTime()+index;
					// 	})
					// }else if(e == 'matrix_scale'){
					// 	this.detial.matrixTopic.forEach((option,index)=>{
					// 		option['textKey'] = 'ques'+new Date().getTime()+index;
					// 	})
					// }else if(e.modelType == 'ganged'){
					// 	this.detial.gangedArray.forEach((option,index)=>{
					// 		option['kayName'] = 'ques'+new Date().getTime()+index
					// 	})
					// }
				}
			})
			// 重新获取类型的默认值，detial
		},
		changeValue(){
			
			let txt = this.detial.topicOption[0].textVal;
			if(txt.indexOf('_')>-1){
				let newStr = txt.match(/_+/g);
				let newCont = txt;
				let names = txt.split(/_+/g);
				let domCont = [];console.log(newStr,newCont,)
				newStr.forEach((item,index)=>{
					newCont = newCont.replace(item,'<em><input type="text" style="width:'+item.length*8+'px;"/></em>'); //将‘_’替换成input
					domCont.push({
						name:names[index],
						width:item.length*8+'px'
					})
				})
				this.detial.topicOption[0].textHtmlVal = newCont;
				this.detial.topicOption[0].domHtml = domCont;
			}else{
				this.$message.error("请填写准确的问题")
			}
		},
		changeRests(){
			let txt = this.detial.topicOption[this.detial.topicOption.length-1].textVal;
			if(txt.indexOf('_')>-1){
				let newStr = txt.match(/_+/g);
				let newCont = txt;
				let names = txt.split(/_+/g);
				let domCont = [];
				newStr.forEach((item,index)=>{
					newCont = newCont.replace(item,'<em><input type="text" style="width:'+item.length*8+'px;"/></em>'); //将‘_’替换成input
					domCont.push({
						name:names[index],
						width:item.length*8+'px'
					})
				})
				this.detial.topicOption[0].textHtmlVal = newCont;
				this.detial.topicOption[0].domHtml = domCont;
			}else{
				this.$message.error("请填写准确的问题")
			}
		},
		// 编辑确定
        confirmAdd(){
			let optionError = 0;
			if(this.detial.modelType != 'text'){
				if(this.detial.topicName == ''){
					this.$message.error("题目不能为空！");
					return
				}
				if(this.detial.modelType == 'radio' || this.detial.modelType == 'select' || this.detial.modelType == 'checked'){
					let options = this.detial.topicOption
					options.forEach((option,index) => {
						if(option.rests == 1){
						let opt = options.splice(index,1)[0]
							options.push(opt)
						}
					});
					let topicOption=[];
					options.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal,
							rests:option.rests
						}
					});
					this.detial.topicOption = options;
					if(optionError == 1){
						this.$message.error("问题选项不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("问题选项不能重复！");
						return
					}
				}else if(this.detial.modelType == 'scale'){
					if(this.detial.scale_type != '自定义'){
						this.detial.scale_type_start = '非常不'+this.detial.scale_type;
						this.detial.scale_type_end = '非常'+this.detial.scale_type
					}
					if(this.detial.scale_type == ''){
						this.$message.error("量表类型不能为空！");
						return
					}else if(this.detial.scale_type_start == ''||this.detial.scale_type_end == ''){
						this.$message.error("量表程度不能为空！");
						return
					}else if(this.detial.scale_scope == ''){
						this.$message.error("量表范围不能为空！");
						return
					}else if(this.detial.scale_start == ''){
						this.$message.error("量表初始值不能为空！");
						return
					}
				}else if(this.detial.modelType == 'matrix_radio' || this.detial.modelType == 'matrix_checked'){
					// 问题项
					let matrixTopic = this.detial.matrixTopic
					let topicQuesOption=[];
					matrixTopic.forEach(option => {
						if(option.textVal == ''){
							optionError = 3;
							return
						}
						topicQuesOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 4;
								return
							}
						})
						topicQuesOption[topicQuesOption.length]={
							textVal:option.textVal
						}
					});
					this.detial.matrixTopic = matrixTopic;
					if(optionError == 3){
						this.$message.error("问题不能为空！");
						return
					}else if(optionError == 4){
						this.$message.error("问题不能重复！");
						return
					}
					let options = this.detial.matrixOption
					let topicOption=[];
					options.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					this.detial.topicOption = options;
					if(optionError == 1){
						this.$message.error("选项不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("选项不能重复！");
						return
					}
				}else if(this.detial.modelType == 'matrix_scale'){
					// 问题项
					let matrixTopic = this.detial.matrixTopic
					let topicQuesOption=[];
					matrixTopic.forEach(option => {
						if(option.textVal == ''){
							optionError = 3;
							return
						}
						topicQuesOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 4;
								return
							}
						})
						topicQuesOption[topicQuesOption.length]={
							textVal:option.textVal
						}
					});
					this.detial.matrixTopic = matrixTopic;
					if(optionError == 3){
						this.$message.error("问题不能为空！");
						return
					}else if(optionError == 4){
						this.$message.error("问题不能重复！");
						return
					}
					if(this.detial.scale_type != '自定义'){
						this.detial.scale_type_start = '非常不'+this.detial.scale_type;
						this.detial.scale_type_end = '非常'+this.detial.scale_type
					}
					if(this.detial.scale_type == ''){
						this.$message.error("量表类型不能为空！");
						return
					}else if(this.detial.scale_type_start == ''||this.detial.scale_type_end == ''){
						this.$message.error("量表程度不能为空！");
						return
					}else if(this.detial.scale_scope == ''){
						this.$message.error("量表范围不能为空！");
						return
					}else if(this.detial.scale_start == ''){
						this.$message.error("量表初始值不能为空！");
						return
					}
				}else if(this.detial.modelType == 'ganged'){
					let gangedName = this.detial.gangedName
					let gangedNameOption=[];
					if(gangedName.length>0){
						gangedName.forEach(option => {
							if(option == ''){
								optionError = 1;
								return
							}
							gangedNameOption.forEach(item=>{
								if(option == item){
									optionError = 2;
									return
								}
							})
							gangedNameOption[gangedNameOption.length]=option
						});
					}else{
						optionError = 3;
						return
					}
					
					if(optionError == 1){
						this.$message.error("级联标题不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("级联标题不能重复！");
						return
					}else if(optionError == 3){
						this.$message.error("请添加级联标题！");
						return
					}
					
				}else if(this.detial.modelType == 'imagesRadio' || this.detial.modelType == 'imagesChecked'){
					let options = this.detial.topicOption
					let topicOption=[];
					options.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					this.detial.topicOption = options;
					if(optionError == 1){
						this.$message.error("图片名称不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("图片名称不能重复！");
						return
					}
				}else if(this.detial.modelType == 'lc-classify'){
					let topicOption=[];
					this.detial.matrixOption.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					if(optionError == 1){
						this.$message.error("选项不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("选项不能重复！");
						return
					}
					let classifyOption=[];
					this.detial.matrixTopic.forEach(option => {
						if(option.textVal == ''){
							optionError = 3;
							return
						}
						classifyOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 4;
								return
							}
						})
						classifyOption[classifyOption.length]={
							textVal:option.textVal
						}
					});
					if(optionError == 3){
						this.$message.error("类别名称不能为空！");
						return
					}else if(optionError == 4){
						this.$message.error("类别名称不能重复！");
						return
					}
				}else if(this.detial.modelType == 'lc-blanks'){
					let topicOption=[];
					this.detial.topicOption.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					if(optionError == 1){
						this.$message.error("问题不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("问题不能重复！");
						return
					}
				}else if(this.detial.modelType == 'lc-table02'){
					let topicOption=[];
					this.detial.topicOption.forEach(option => {
						if(option.textVal == ''){
							optionError = 1;
							return
						}
						topicOption.forEach(item=>{
							if(option.textVal == item.textVal){
								optionError = 2;
								return
							}
						})
						topicOption[topicOption.length]={
							textVal:option.textVal
						}
					});
					if(optionError == 1){
						this.$message.error("问题不能为空！");
						return
					}else if(optionError == 2){
						this.$message.error("问题不能重复！");
						return
					}
				}
				
			}
			this.detial.editState = false;
			this.$emit('confirm-add',this.detial)
			
		},
		//关闭添加页面
		closeTopicAddEdit(){
			this.detial.editState = false;
			this.$emit('close-add',this.detial.editState)
		},
		// 是否有其他选项
		getRests(){
			this.$nextTick(()=>{
				if(this.detial.topicOption){
					this.detial.topicOption.forEach(option => {
						if(option.rests == 1){
							this.isRests = true
						}else{
							this.isRests = false
						}
					})
				}
				
			})
		},
		// 新增选项
		addTopicOption(obj,stringType){
			let option = {};
			if(stringType == 'rests_table'){
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:'其他____________________',
					rests:1,
					textHtmlVal:'其他<em><input type="text" style="width:160px"></em>',
					domHtml:[{
						width:'160px',
						name:'其他',
						value:''
					}],
				}
				this.isRests = true
			}else{
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:'',
					rests:0
				}
			}
			
			this.detial.topicOption.push(option)
		},
		// 新增问题
		addMatrixOption(type){
			let option = {}
			if(type == '问题'){
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:''
				}
				this.detial.matrixTopic.push(option);
				this.detial.value.push([])
			}else if(type == '选项'){
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:'',
				}
				this.detial.matrixOption.push(option)
			}else if(type == 'blanks'){
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:'',
				}
				this.detial.topicOption.push(option)
				this.detial.value.push('')
			}else if(type == 'classify'){
				option = {
					textKey:'ques'+new Date().getTime(),
					textVal:'',
					children:[]
				}
				this.detial.matrixTopic.push(option)
			}else if(type == 'table'){
				option = {
					textKey:'ques'+new Date().getTime(),
					value:'',
					quesType:'blanks',
					matrixTopic:'',
					quesValue:''
				}
				this.detial.topicOption.push(option)
			}else{
				option = {
					value:''
				}
				this.detial.topicOption.push(option)
			}
			
		},
		//删除问题选项
		deleteTopicOption(index, stringType){
			if(stringType == '问题'){
				this.detial.matrixTopic.splice(index,1)
				this.detial.value.splice(index,1)
			}else if(stringType == '选项'){
				this.detial.matrixOption.splice(index,1)
			}else if(stringType == 'rests_table'){
				this.detial.topicOption.splice(index,1)
				this.isRests = false
			}else if(stringType == 'classify'){
				this.detial.matrixTopic.splice(index,1)
			}else{
				this.detial.topicOption.splice(index,1)
			}
			
		},
		// 添加级联选项标题
		addTitle(){
			this.detial.gangedName.push('')
		},
		// 删除级联选项标题
		deleteTitle(n){
			this.$confirm('是否删除一整列？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.detial.gangedName.splice(n,1)
				}).catch(() => {
					this.$message.info("已取消")  
				});
			
		},
		// 级联选项新增
		addGangedOption(n){
			let option = {}
			if(n == 1){
				option = {
					kayName:'ques'+new Date().getTime(),
					kayVal: "",
					kayArray:[]
				}
				this.detial.gangedArray.push(option);
				this.detial.curOpt1 = this.detial.gangedArray.length-1;
				this.detial.curOpt2 = '';
				this.detial.curOpt3 = '';
				this.$nextTick(() => {
					this.$refs['first'+Number(this.detial.curOpt1)][0].focus(); 
				})
			}else if(n == 2){
				option = {
					kayName:'ques'+new Date().getTime(),
					kayVal: "",
					kayArray:[]
				}
				this.detial.gangedArray[this.detial.curOpt1].kayArray.push(option)
				this.detial.curOpt2 = this.detial.gangedArray[this.detial.curOpt1].kayArray.length-1;
				this.detial.curOpt3 = '';
				console.log(this.detial.gangedArray[this.detial.curOpt1].kayArray)
				this.$nextTick(() => {
					this.$refs['second'+Number(this.detial.curOpt2)][0].focus();
				})
			}else{
				option = {
					kayName:'ques'+new Date().getTime(),
					kayVal: ""
				}
				this.detial.gangedArray[this.detial.curOpt1].kayArray[this.detial.curOpt2].kayArray.push(option);
				this.detial.curOpt3 = this.detial.gangedArray[this.detial.curOpt1].kayArray[this.detial.curOpt2].kayArray.length-1;
				this.$nextTick(() => {
					this.$refs['third'+Number(this.detial.curOpt3)][0].focus(); 
				})
			}
		},
		// 级联选项删除
		deleteGangedOption(index,n){
			this.$confirm('删除该项会同时删除其下一级的所有选项，确认此操作吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(n==1){
						this.detial.gangedArray.splice(index,1)
						this.detial.curOpt1 = '';
						this.detial.curOpt2 = '';
						this.detial.curOpt3 = '';
					}else if(n==2){
						this.detial.gangedArray[this.detial.curOpt1].kayArray.splice(index,1)
						this.detial.curOpt2 = '';
						this.detial.curOpt3 = '';
					}else{
						this.detial.gangedArray[this.detial.curOpt1].kayArray[this.detial.curOpt2].kayArray.splice(index,1)
						this.detial.curOpt3 = ''
					}
				}).catch(() => {
					this.$message.info("已取消")  
				});
			
		},
		//级联选项失去焦点事件
		loseFocus(index,n){
			let name = '';
			if(n==1){
				name = this.detial.gangedArray[index].kayVal;
			}else if(n==2){
				name = this.detial.gangedArray[this.detial.curOpt1].kayArray[index].kayVal;
			}else{
				name = this.detial.gangedArray[this.detial.curOpt1].kayArray[this.detial.curOpt2].kayArray[index].kayVal;
			}
			if(name == ''){
				this.$confirm('不填写数据将删除该项及其下一级的所有选项，确认此操作吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(n==1){
						this.detial.gangedArray.splice(index,1)
						this.detial.curOpt1 = '';
						this.detial.curOpt2 = '';
						this.detial.curOpt3 = '';
					}else if(n==2){
						this.detial.gangedArray[this.detial.curOpt1].kayArray.splice(index,1)
						this.detial.curOpt2 = '';
						this.detial.curOpt3 = '';
					}else{
						this.detial.gangedArray[this.detial.curOpt1].kayArray[this.detial.curOpt2].kayArray.splice(index,1)
						this.detial.curOpt3 = ''
					}
				}).catch(() => {
					if(n==1){
						this.$refs['first'+Number(this.detial.curOpt1)][0].focus(); 
					}else if(n==2){
						this.$refs['second'+Number(this.detial.curOpt2)][0].focus(); 
					}else{
						this.$refs['third'+Number(this.detial.curOpt3)][0].focus(); 
					}      
				});
			}
		},
		//级联选项获取焦点事件
		acquireFocus(index,n){
			// console.log(this.detial,this.detial.curOpt1)
			if(n==1){
				this.detial.curOpt1 = index;
				this.detial.curOpt2 = '';
				this.detial.curOpt3 = '';
			}else if(n==2){
				this.detial.curOpt2 = index;
				this.detial.curOpt3 = '';
			}else{
				this.detial.curOpt3 = index;
			}
			// this.$forceUpdate()
			// console.log(this.detial.curOpt1 )
		},
		// 上传图片
		handleImgSuccess(res, file,filelist) {
			if(filelist.every(item=>item.status=='success')){
				filelist.map((item,index)=>{
					item.response && this.detial.topicOption.push({
						textKey:'ques'+new Date().getTime()+index,
						textVal:item.name,
						imgSrc:item.response.data.modelimg
					})
				})
			}
		},	
		// 上传前的校验
		beforeImgUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 5;
			if (!isLt2M) {
			this.$message.error('图片大小不能超过 5MB!');
			}
			return isLt2M;
		},
		handleRemove(index) {
			this.detial.topicOption.splice(index,1);
		},
		handleChange(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
	
    }
};
</script>
<style lang="less" scoped>
.field-item{
	margin-bottom: 5px;
	position: relative;
	border:1px solid transparent;
	&:hover{
		border:1px dashed var(--theme-color);
	}
	input[type=text],textarea{ 
		border:1px solid #e5e5e5;
		box-sizing: border-box;
		border-radius: 2px;
		font-size: 14px;
		color: #666;
		outline: none;
		resize: none;
	}
	input[type=text]:focus,textarea:focus{
		border-color:#0cf;
	}

	.form-group{
		width: 100%;
		td{
			padding:5px 10px;
		}
		#formType td{
			padding-bottom: 0;
		}
		.control-panel{
			.form_select{
				width: 140px;
			}
			.area-tip{
				color:#ff8712;
				font-size:13px;
				line-height:24px;
				padding-top:10px;
				padding-left:1em;
			}

			.form_item{
				margin: 0 200px 0 0;
				position: relative;
			}
			.form-control,.linkage_title{
				width:100%;
				line-height: 28px;
				margin: 0;
				text-align: left;
			}
			/deep/ .input_underline{border:none;border-bottom:1px solid #555;margin-left:5px;}
			.place_holder{color: #999;}
			textarea.form-control{
				height: 60px;
			}
			.hblank-txt .form-control{
				padding:0;
				span{
					display: none;
				}
				input{
					border:none;
					width: 100%;
					height:40px;
				}
			}
			.set_bold,.upload_img,.set_blank {
				display: none;
				width: 30px;
				height: 30px;
				text-align: center;
				line-height: 30px;
				font-weight: bold;
				border: 1px solid #eee;
				font-size: 18px;
				color: #999!important;
				position: absolute;
				background: #fff;
				z-index: 9;
			}
			.set_bold {
				top: -22px;
				left: 10px;
			}
			.upload_img {
				top: -22px;
				left: 41px;
				background: url(../assets/images/form-icons.png) no-repeat -70px -45px #fff;
			}
			.upload_img {
				top: -22px;
				left: 72px;
			}
			
			.checkbox-text{
				margin-left: 20px;
			}
			.form_table{
				width: 100%;
				td{
					padding:5px 0;
				}
			}
			.deleteOption{
				position: absolute;
				right: -30px;top: 50%;
				color: #999;
				font-size: 24px;
				line-height: 1;
				transform: translateY(-50%);
			}
			.add_option_btn{
				margin: 0 200px 0 0;
				position: relative;
				a{
					display: block;
					height: 38px;
					line-height: 38px;
					border: 1px dashed #ddd;
					padding: 0 10px;
					margin:5px 0;
					color: #333;
				}
			}
			.add_other{
				a{
					color:#0cf;
				}
				.font-gray{
					color:#ccc;
					cursor: default;
				}
			}
			.bottom_btn{
				margin: 10px 0 0;
				text-align: center;
				.form-save{
					color: #fff;
					background: #0cf;
					border-color: #0cf;
					&:hover{
						color: #0cf;
						background: #fff;
					}
				}
				.form-cancel{
					color: #555;
					&:hover{
						border-color: #C9C9C9;
					}
				}
			}
			// 量表题
			.inventory_problem{
				padding-top: 5px;
			} 
			.inventory_item{
				margin: 0 20px 0 0;
				label{
					margin-right: 10px;
				}
				.form-control,.form_select{
					width: 100px;
				}
			}
			// 矩阵单选、矩阵多选
			.matrix_single{
				display: flex;
				.matrix_sitem{
					flex: 1;
					.matrix_stitle{
						font-size: 16px;
						font-weight: bold;
					}
					.form_item{
						margin: 0 150px 0 50px;
					}
					.add_option_btn{
						margin: 0 150px 0 50px;
					}
					.inventory_item{
						margin: 0;
					}
				}
			}
			// 联动
			.linkage_btn,.linkage_warp{
				float: left;
				padding-top: 5px;
			}
			.linkage_item{
				margin: 0 10px 0 0;
				float: left;
				.form-control{
					width: 200px;
				}
				.linkage_top{
					margin: 0 0 10px;
					position: relative;
					.deleteOption{
						display: none;
						right: 5px;
					}
				}
				.linkage_option{
					border: 1px solid #eaeff1;
					.addlink_option{
						display: block;
						padding: 0 5px;
						height: 40px;
						line-height: 40px;
						color: #666;
					}
					.option_item{
						position: relative;
						line-height: 2;
						cursor: pointer;
						.form-control{
							padding: 5px 35px 5px 5px;
							height: 40px;
						}
						a{
							display: none;
							position: absolute;
							right: 10px;
							top: 10px;
							font-size: 18px;
							line-height: 1;
							color: #666!important;
						} 
						/deep/ .el-input__inner{
							display: block;
							width: 100%;
							height: 100%;
							border: none;
							background: none;
						}
						&:hover{
							background: #f5f5f5;
							a{
								display: block;
							}
						}
						&:after{
							content: "";
							display: none;
							width: 0;
							height: 0;
							border-width: 5px 8px;
							border-style: solid;
							border-color: transparent transparent transparent #aaa;
							position: absolute;
							top: 10px;
							right: 0;
						}
						&:last-child{
							border-bottom: 1px solid #eaeff1;
						}
					}
					.option_item.active{
						background: #0cf;
						/deep/ .el-input__inner{
							color: #fff;
						}
						&:hover{
							background: #0cf;
						}
					}
					.option_item.has_next:after{
						display: block;
					}
				}
				&:last-child .linkage_top:hover .deleteOption{
					display: block;
				}
				.linkage_tips{
					height: 40px;
					line-height: 40px;
				}
			}
			.showimg_list{
				margin: 0 -5px;
				li{
					display: inline-block;
					width: 140px;
					min-height: 180px;
					border: 1px solid #DCDFE6;
					border-radius: 2px;
					padding: 0;
					margin: 5px;
					vertical-align: top;
					position: relative;
					background-color: #fff; 
					.showimg_box{
						width: 100%;
						height: 138px;
						border-bottom: 1px solid #DCDFE6;
						position: relative;
						overflow: hidden;
						background: #f5f5f5; 
						img{
							max-width: 100%;
							max-height: 100%;
							margin: auto;
							position: absolute;
							top: 0;right: 0;
							bottom: 0;left: 0;
						}
						.showimg_opbtn{
							display: none;
							position: absolute;
							left: 2px;
							right: 2px;
							bottom: 2px;
							font-size: 0;
							a{
								display: inline-block;
								width:100%;
								height: 24px;
								line-height: 24px;
								text-align: center;
								color: #fff;
								font-size: 12px;
								background-color: #484848;
								cursor: pointer;
							}
							a+a{
								margin-left: 2px;
							}
						}
						&:hover{
							.showimg_opbtn{
								display: block;
							}
						}
					}
					.showimg_name{
						font-size: 14px;
						padding:9px 6px;
						white-space: nowrap;
						overflow: hidden;
						outline: none;
						cursor: pointer;
						/deep/ .el-input__inner{
							width: 100px;
							border:none;
							padding:0;
						}
					}
				}
				.showimg_addbtn{
					height: 180px;
					border:none;
					text-align: center;
					vertical-align: middle;
					font-size: 14px;
					cursor: pointer;
					.avatar-uploader{
						/deep/ .el-upload {
							position: relative;
							overflow: hidden;
							border-radius: 0;
							border-color: #DCDFE6;
							padding:40px 0;
							height: 100%;
							.avatar-uploader-icon {
								font-size: 28px;
								color: #bbb;
								height: 68px;
								line-height: 68px;
								text-align: center;
								display: block;
							}
							.avatar-txt {
								line-height: 30px;
								display: block;
								color:#bbb;
							}
							&:hover{
								border-color: #0cF;
								.avatar-uploader-icon,.avatar-txt{
									color: #0cF;
								}
							}
						}
					} 
				}
			}
			// 自增表格
			.matrix_table02{
				padding-right: 30px;
				.matrix_stitle{
					font-size: 16px;
					font-weight: bold;
				}
				table{
					table-layout: fixed;
					td:last-child{
						width: 0;
					}
				}
				.form_item,.add_option_btn{
					margin-right: 10px;
				}
				.form_select{width: 100%;}
			}
		} 
	}
}



</style>