<template>
    
	<div class="field-zitem" type="lc-location" :id="detial.uuid" v-if="!logicFlag || (logicFlag && detial.jumpShow && detial.logicShow)">
        <!-- 单选题 -->
        <div class="field_warp">
            <h5 class="field_title">
                <i v-if="detial.topic_required" class="required">*</i>
                <span class="txt" :style="{fontSize: globalItemStyle.labelStyle.fontSize + 'px',color: globalItemStyle.labelStyle.color,fontWeight: globalItemStyle.labelStyle.fontWeight,fontStyle: globalItemStyle.labelStyle.fontStyle,textDecoration: globalItemStyle.labelStyle.textDecoration}">{{index+1}}.{{detial.topicName}}</span>
            </h5>
            <p class="field_remark" v-if="detial.remarks">{{detial.remarks}}</p>
            <div class="item_contianer">
                <table>
                    <tr class="lc-select lcfont">
                        <td class="area-text">省</td>
                        <td>
                            <el-select v-model="detial.value[0]" class="form-control" @change="provinceChange" v-if="provinces && provinces.length > 0">
                                <el-option value="" label="请选择"></el-option>
                                <el-option :value="item.adcode" :label="item.name" v-for="(item,index) in provinces" :key="index"></el-option>
                            </el-select>
                            <el-select v-model="detial.value[0]" class="form-control" v-else>
                                <el-option value="" >请选择</el-option>
                            </el-select>
                        </td>
                        <td class="area-text">市</td>
                        <td>
                            <el-select v-model="detial.value[1]" class="form-control" @change="cityChange" v-if="cities && cities.length > 0">
                                <el-option value="">请选择</el-option>
                                <el-option :value="item.adcode" :label="item.name" v-for="(item,index) in cities" :key="index"></el-option>
                            </el-select>
                            <el-select v-model="detial.value[1]" class="form-control" v-else>
                                <el-option value="" >请选择</el-option>
                            </el-select>
                        </td>
                        <td class="area-text">县</td>
                        <td>
                            <el-select v-model="detial.value[2]" class="form-control" v-if="districts && districts.length > 0">
                                <el-option value="" >请选择</el-option>
                                <el-option :value="item.adcode" :label="item.fullname" v-for="(item,index) in districts" :key="index"></el-option>
                            </el-select>
                            <el-select v-model="detial.value[2]" class="form-control" v-else>
                                <el-option value="" >请选择</el-option>
                            </el-select>
                        </td>
                    </tr>
                </table>
            </div>
                <div class="area-tip">注：详细省市列表可在保存后到前台查看，后台不支持显示。</div>
        </div>
	</div>
</template>
<script>
import $ from "jquery";
import { post } from "/src/services/ajax_ucenter.js";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
    data(){
        return {
            provinces:[],
            cities:[],
            districts:[]
        }
    },
	watch: {
		'globalItemStyle.itemStyle': {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.globalItemStyle.itemStyle;
                    $("#" + this.detial.uuid + ".field-zitem .lc-select .area-text").css({
                        fontSize:styleStr.fontSize+'px',
                        color: styleStr.color,
                        fontWeight: styleStr.fontWeight,
                        fontStyle:styleStr.fontStyle,
                        textDecoration:styleStr.textDecoration,
                    });
                });
			},
			deep: true,
			immediate: true,
		},
        list:{
            handler(){
                this.$nextTick(()=>{
                    let show = 0;
                    if(this.detial.showLogic && this.detial.showLogic.length > 0){
                        if(this.detial.showLogicCondition == 1){
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    o.value.includes(item.logicOption)&&show++;
                                                }
                                                
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    !(o.value.includes(item.logicOption))&&show++;
                                                }
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show == this.detial.showLogic.length){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }else{
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show > 0){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }
                        
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        detial:{
            handler(){
                this.$nextTick(()=>{
                    if(this.detial.logicJumps && this.detial.logicJumps.length > 0){
                        let sort = 0;
                        this.detial.logicJumps.forEach(item => {
                            this.list.forEach(o=>{
                                if(this.detial.uuid == o.uuid && o.logicShow && o.jumpShow){
                                    let newItem = this.list.filter(c => c.uuid == item.logicTopic)
                                    sort = newItem[0].sort;
                                }
                            })
                        });
                        this.list.forEach(o=>{
                            if(sort){
                                if(o.sort < sort && o.sort > this.detial.sort){
                                    o.jumpShow = false;
                                }else if(o.sort >= sort){
                                    o.jumpShow = true;
                                }
                            }
                        })
                    }
                })
            },
			deep: true,
			immediate: true,
        }
	},
    created(){
        this.getProvince();
        if(this.detial.value[0]){
            this.provinceChange(this.detial.value[0])
        }
        if(this.detial.value[1]){
            this.cityChange(this.detial.value[1])
        }
    },
    methods:{
        // 获取省列表
        getProvince(){
            const _this = this;
            post('/formadmin/findProvince.jhtml').then(res => {
				if(res.code == 200){
					_this.provinces = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
        },
        // 选择省后获取城市列表
        provinceChange(adcode){
            const _this = this;
            post('/formadmin/findCity.jhtml',{parentAdcode:adcode}).then(res => {
				if(res.code == 200){
					_this.cities = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
        },
        // 选择城市后获取区县列表
        cityChange(adcode){
            const _this = this;
            post('/formadmin/findDistrict.jhtml',{parentAdcode:adcode}).then(res => {
				if(res.code == 200){
					_this.districts = res.data;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
        }
    }
};
</script>
<style lang="less" scoped>
.field-zitem{
    min-height: 190px;
    padding: 0 80px 10px 15px;
    border: 1px solid transparent;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        padding: 0 0 0 1em;

    }
        .area-tip{
            color:#ff8712;
            font-size:13px;
            line-height:24px;
            padding-top:10px;
            padding-left:1em;
        }
}

</style>