<template>
    
	<div class="field-mitem matrix_table02" type="lc-table02" :id="detial.uuid" v-if="!logicFlag || (logicFlag && detial.jumpShow && detial.logicShow)">
        <!-- 单选题 -->
        <div class="field_warp">
            <h5 class="field_title">
                <i v-if="detial.topic_required" class="required">*</i>
                <span class="txt" :style="{fontSize: globalItemStyle.mLabelStyle.fontSize + 'px',color: globalItemStyle.mLabelStyle.color,fontWeight: globalItemStyle.mLabelStyle.fontWeight,fontStyle: globalItemStyle.mLabelStyle.fontStyle,textDecoration: globalItemStyle.mLabelStyle.textDecoration}">{{index+1}}.{{detial.topicName}}</span>
            </h5>
            <p class="field_remark" v-if="detial.remarks">{{detial.remarks}}</p>
            <div class="item_contianer">
                <div class="matrix_table simulate_table">
                    <div class="simulate_thead">
                        <div class="simulate_tr">
                            <div class="simulate_th" v-for="(item,index) in detial.topicOption" :key="index">{{item.textVal}}</div>
                        </div>
                    </div>
                    <div class="simulate_tbody">
                        <div class="simulate_tr" v-for="count in detial.trLength" :key="count">
                            <div class="simulate_td" v-for="(item,index) in detial.topicOption" :key="index">
                                 <el-select class="ques_select" v-model="item.quesValue[count-1]" placeholder="请选择" v-if="item.textType != 'blanks'">
                                    <el-option v-for="option in item.textOption.split(';')" :key="option" :label="option" :value="option"></el-option>
                                </el-select>
                                <el-input class="ques_input" v-model="item.quesValue[count-1]" v-else></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="matrix_addtr" @click="addTr(this)">继续填写</div>
            </div>
        </div>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	watch: {
		'globalItemStyle.mItemStyle': {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.globalItemStyle.mItemStyle;
                    $("#" + this.detial.uuid + ".field-mitem.matrix_table02 .simulate_th").css({
                        fontSize:styleStr.fontSize+'px',
                        color: styleStr.color,
                        fontWeight: styleStr.fontWeight,
                        fontStyle:styleStr.fontStyle,
                        textDecoration:styleStr.textDecoration,
                    });
                });
			},
			deep: true,
			immediate: true,
		},
        list:{
            handler(){
                this.$nextTick(()=>{
                    let show = 0;
                    if(this.detial.showLogic && this.detial.showLogic.length > 0){
                        if(this.detial.showLogicCondition == 1){
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    o.value.includes(item.logicOption)&&show++;
                                                }
                                                
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    !(o.value.includes(item.logicOption))&&show++;
                                                }
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show == this.detial.showLogic.length){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }else{
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show > 0){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }
                        
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        detial:{
            handler(){
                this.$nextTick(()=>{
                    if(this.detial.logicJumps && this.detial.logicJumps.length > 0){
                        let sort = 0;
                        this.detial.logicJumps.forEach(item => {
                            this.list.forEach(o=>{
                                if(this.detial.uuid == o.uuid && o.logicShow && o.jumpShow){
                                    let newItem = this.list.filter(c => c.uuid == item.logicTopic)
                                    sort = newItem[0].sort;
                                }
                            })
                        });
                        this.list.forEach(o=>{
                            if(sort){
                                if(o.sort < sort && o.sort > this.detial.sort){
                                    o.jumpShow = false;
                                }else if(o.sort >= sort){
                                    o.jumpShow = true;
                                }
                            }
                        })
                    }
                })
            },
			deep: true,
			immediate: true,
        }
	},
    methods:{
        addTr(){
            this.detial.trLength++
            this.detial.topicOption.forEach(option => {
                option.quesValue.push('')
            });
        }
    }
};
</script>
<style lang="less" scoped>
.field-mitem{
    padding: 0 10px 10px;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        .field_list{
            .el-radio{
                padding: 3px 0;
                line-height:25px;
                color:#555;
            }
        }
        .field_blankslist li{
            padding: 3px 0;
            line-height:25px;
            color:#555;
        }
        select.form-control{
            width: 200px;
            margin:0 10px 0 0;
        }
        textarea.form-control{
            height: 130px;
        }
    }
}
.simulate_table{
    display: table;
    text-align: center;
    border:1px solid #eee;
    width: 100%;
    .simulate_tr{
        display: flex;
    }
    .simulate_th,.simulate_td{
        display: table-cell;
        flex: 1;
        padding: 5px 0;
        text-align: center;
        border:1px solid #eee;
        /deep/ .el-checkbox__label{
            display: none;
        }
        .ques_input{
            width:90%;
            /deep/ .el-input__inner{
                border:none;
            }
        }
        .ques_select{
             width:90%;
        }
    }
}
.matrix_table02{
    .matrix_addtr{
        width:100%;
        border:1px solid #ddd;
        height:30px;
        line-height:30px;
        margin-top:10px;
        text-align:center;
        cursor: pointer;
        &:hover{color: #0cf;}
    }
}

</style>