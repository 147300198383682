<template>
	<div class="field-zitem text_item special_item" type="text" :id="detial.uuid">
        <!-- 纯文本 -->
        <p>{{detial.textInput}}</p>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	computed: {
		watchAttr() {
			return {
				...this.detial.inputStyle,
				options: this.detial.options,
			};
		},
	},
	watch: {
		watchAttr: {
            handler() {
				this.$nextTick(function () {
                    $("#" + this.detial.uuid ).css({
                        display: "block",
                        margin: 0
                    });
				});
			},
			deep: true,
			immediate: true,
		},
	},
    methods:{
        
    }
};
</script>
<style lang="less" scoped>
.field-zitem{
    min-height: 190px;
    padding: 0 80px 10px 15px;
    border: 1px solid transparent;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        .field_list{
            .el-radio{
                padding: 3px 0;
                line-height:25px;
                color:#555;
            }
        }
        .field_blankslist li{
            padding: 3px 0;
            line-height:25px;
            color:#555;
        }
        select.form-control{
            width: 200px;
            margin:0 10px 0 0;
        }
        textarea.form-control{
            height: 130px;
        }
        .inventory_problem{
            .inventory_tips{
                color: #666;
                font-size: 12px;
                float: left;
                height: 30px;
                line-height: 30px;
            }
            .inventory_list{
                margin: 0 5px;
                float: left;
                height: 30px;
                line-height: 30px;
                li{
                    width: 50px;
                    text-align: center;
                } 
            }
        }
        .simulate_table{
            display: table;
            text-align: center;
            border:1px solid #eee;
            .simulate_tr{
                display: flex;
            }
            .simulate_th,.simulate_td{
                display: table-cell;
                flex: 1;
                padding: 5px 0;
                text-align: center;
                border:1px solid #eee;
            }
        }
        .matrix_table{
            width: 100%;
            td,th{
                font-weight: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
                border-bottom: 1px solid #ebebeb;
                line-height: 30px;
            }
            td{
                height: 60px;
                line-height: 60px;
            }
        }
        .matrix_inventory{
            th {
                min-width: 80px;
                font-size: 12px;
                color: #666;
            }
        }
        .link_age{
            .linkage_item{
                float: left;
                .linkage_title{
                    min-width: 157px;
                    padding: 3px 5px;
                    margin: 5px 0 0;
                    line-height: 1.8;
                    color: #555;
                }
            }
        }
    }
}

.field-zitem.text_item{
    min-height:84px;
    line-height: 36px;
}
.field-zitem.hr{
    min-height: 0;
    line-height: 36px;
}

</style>