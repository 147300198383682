<template>
    
	<div class="field-mitem images_chose" type="imagesChecked" :id="detial.uuid" v-if="!logicFlag || (logicFlag && detial.jumpShow && detial.logicShow)">
        <!-- 单选题 -->
        <div class="field_warp">
            <h5 class="field_title">
                <i v-if="detial.topic_required" class="required">*</i>
                <span class="txt" :style="{fontSize: globalItemStyle.mLabelStyle.fontSize + 'px',color: globalItemStyle.mLabelStyle.color,fontWeight: globalItemStyle.mLabelStyle.fontWeight,fontStyle: globalItemStyle.mLabelStyle.fontStyle,textDecoration: globalItemStyle.mLabelStyle.textDecoration}">{{index+1}}.{{detial.topicName}}</span>
            </h5>
            <p class="field_remark" v-if="detial.remarks">{{detial.remarks}}</p>
            <div class="item_contianer">
                <ul class="showimg_list" v-if="detial.topicOption.length>0">
                    <li class="showimg_item" v-for="(item,index) in detial.topicOption" :key="index">
                        <div class="showimg_box">
                            <img :src="item.imgSrc">
                        </div>
                        <div class="showimg_name">
                            <el-checkbox :label="item.textKey" v-model="detial.value">{{item.textVal}}</el-checkbox>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	watch: {
		'globalItemStyle.mItemStyle': {
            handler() {
                this.$nextTick(function () {
                    const styleStr = this.globalItemStyle.mItemStyle;
                    $("#" + this.detial.uuid + ".field-mitem.images_chose .el-checkbox__label").css({
                        fontSize:styleStr.fontSize+'px',
                        color: styleStr.color,
                        fontWeight: styleStr.fontWeight,
                        fontStyle:styleStr.fontStyle,
                        textDecoration:styleStr.textDecoration,
                    });
                });
			},
			deep: true,
			immediate: true,
		},
        list:{
            handler(){
                this.$nextTick(()=>{
                    let show = 0;
                    if(this.detial.showLogic && this.detial.showLogic.length > 0){
                        if(this.detial.showLogicCondition == 1){
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    o.value.includes(item.logicOption)&&show++;
                                                }
                                                
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                if(o.value){
                                                    !(o.value.includes(item.logicOption))&&show++;
                                                }
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show == this.detial.showLogic.length){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }else{
                            this.detial.showLogic.forEach(item => {
                                this.list.forEach(o=>{
                                    if(item.logicTopic == o.uuid){
                                        if(item.logicCondition == 1){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart <= o.value && item.logicEnd >= o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                            
                                        }else if(item.logicCondition == 2){
                                            if(item.quesItem.modelType == 'scale'){
                                                (item.logicStart > o.value && item.logicEnd < o.value)&&show++;
                                            }else{
                                                o.value.includes(item.logicOption)&&show++;
                                            }
                                        }else if(item.logicCondition == 3){
                                            if(o.logicShow && o.jumpShow){
                                                show++;
                                            }
                                             
                                        }else if(item.logicCondition == 4){
                                            if(!o.logicShow || !o.jumpShow){
                                                show++;
                                            }
                                        }
                                    }
                                })
                            });
                            if(show > 0){
                                this.detial.logicShow = true;
                            }else{
                                this.detial.logicShow = false;
                            }
                        }
                        
                    }
                })
            },
			deep: true,
			immediate: true,
        },
        'detial.value':{
            handler(){
                this.$nextTick(()=>{
                    if(this.detial.logicJumps && this.detial.logicJumps.length > 0){
                        let sort ;
                        this.detial.logicJumps.forEach(item => {
                            this.list.forEach(o=>{
                                if(this.detial.uuid == o.uuid && item.logicOptionArray.length === o.value.length && item.logicOptionArray.every((ele) => o.value.includes(ele))){
                                    let newItem = this.list.filter(c => c.uuid == item.logicTopic)
                                    sort = newItem[0].sort;
                                }
                            })
                        });
                        this.list.forEach(o=>{
                            if(sort){
                                if(o.sort < sort && o.sort > this.detial.sort){
                                    o.jumpShow = false;
                                }else if(o.sort >= sort){
                                    o.jumpShow = true;
                                }
                            }
                        })
                    }
                })
            },
			deep: true,
			immediate: true,
        }
	},
    methods:{
    }
};
</script>
<style lang="less" scoped>
.field-mitem{
    padding: 0 10px 10px;
    cursor: move;
    position: relative;
    overflow: hidden;
    .field_title{
        font-size: 16px;
        line-height: 1.8;
        padding: 10px 0 5px;
        position: relative;
        .required{
            font-weight: 700;
            color: #f00;
            position: absolute;
            top: 18px;
            left: -10px;
            line-height: 1;
        }
    }
    .field_remark{
        padding: 0 0 0 1em;
        line-height: 1.8;
        color: #999;
    }
    .item_contianer{
        .showimg_list{
            margin: 0 -5px;
            display: flex;
            flex-wrap: wrap;
            .showimg_item{
               width:calc(50% - 10px);
               min-height: 174px;
               border: 1px solid #DCDFE6;
               border-radius: 2px;
               padding: 0;
               margin: 5px;
               vertical-align: top;
               position: relative;
               background-color: #fff; 
                .showimg_box{
                    width: 100%;
                    height: 138px;
                    border-bottom: 1px solid #DCDFE6;
                    position: relative;
                    overflow: hidden;
                    background: #f5f5f5;
                } 
                img{
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                    position: absolute;
                    top: 0;right: 0;
                    bottom: 0;left: 0;
                }
                .showimg_name{
                    font-size: 14px;
                    padding:6px;
                    white-space: nowrap;
                    overflow: hidden;
                    outline: none;
                    cursor: pointer;
                }
            }
        }

    }
}

</style>