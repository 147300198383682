<template>
	<el-dialog title="逻辑" class="logic_main" :visible.sync="detial.logicDialog" @close="cancelLogic">
        <ul class="table_nav cl">
            <li :class="{active:logicType == 1}" v-if="quesList.length > 0">
                <a href="javascript: void(0);" @click="logicSwitcher(1);">跳转逻辑</a>
            </li>
            <li :class="{active:logicType == 2}" v-if="index > 0">
                <a href="javascript: void(0);" @click="logicSwitcher(2);">显示逻辑</a>
            </li>
        </ul>
        <div class="logic_warp">
            <!-- 跳转逻辑 -->
            <template v-if="logicType == 1 ">
                <div class="logic_jump" v-if="detial.modelType != 'checked' && detial.modelType != 'imagesChecked'">
                    <p class="logic_tips">共 <span class="logic_jump_count">{{detial.logicJumps?detial.logicJumps.length:0}}</span> 条跳转逻辑</p>
                    <table class="jump_single_table">
                        <tbody>
                            <tr class="jumpconstraint" v-for="(item,idx) in detial.logicJumps" :key="'jump'+idx">
                                <td>如果本题选中</td>
                                <td v-if="detial.modelType == 'scale'">
                                    <el-select class="option_select score_select" v-model="item.logicStart">
                                        <el-option label="" value="请选择"></el-option>
                                        <el-option v-for="(count,i) in Number(detial.scale_scope)" :key="i" :label="(i+Number(detial.scale_start))+'分'" :value="i+Number(detial.scale_start)"></el-option>
                                    </el-select>
                                至
                                    <el-select class="option_select score_select" v-model="item.logicEnd">
                                        <el-option label="" value="请选择"></el-option>
                                        <el-option v-for="(count,i) in Number(detial.scale_scope)" :key="i" :label="(i+Number(detial.scale_start))+'分'" :value="i+Number(detial.scale_start)"></el-option>
                                    </el-select>
                                </td>
                                <td v-else-if="detial.modelType == 'radio'|| detial.modelType == 'select'">
                                    <el-select class="option_select" v-model="item.logicOption">
                                        <el-option label="" value="请选择选项"></el-option>
                                        <el-option v-for="(option,i) in detial.topicOption" :key="i" :label="option.textVal" :value="option.textKey"></el-option>
                                    </el-select>
                                </td>
                                <td v-else-if="detial.modelType == 'imagesRadio'">
                                    <el-select class="option_select" v-model="item.logicOption">
                                        <el-option label="" value="请选择选项"></el-option>
                                        <el-option v-for="(option,i) in detial.topicOption" :key="i" :label="option.textVal" :value="option.textKey"></el-option>
                                    </el-select>
                                </td>
                                <td v-else>
                                    <el-select class="option_select" v-model="item.logicOption">
                                        <el-option label="" value="请选择选项"></el-option>
                                        <el-option label="显示" value="显示"></el-option>
                                    </el-select>
                                </td>
                                <td>则跳转到</td>
                                <td>
                                    <el-select class="option_select" v-model="item.logicTopic">
                                        <el-option label="" value="请选择题目"></el-option>
                                        <el-option v-for="(option,i) in quesList" :key="i" :label="option.topicName" :value="option.uuid"></el-option>
                                    </el-select>
                                </td>
                                <td>
                                    <div class="change_warp">							
                                        <a class="change_num reduce_btn" href="javascript: void(0);" @click="delLogic('logicJumps',idx)"></a>
                                        <a class="change_num add_btn" href="javascript: void(0);" @click="addLogic('logicJumps',idx)"></a>
                                    </div>
                                </td>
                            </tr>
                            <tr class="btn-wrapper">
                                <td colspan="5" width="100%">
                                    <div>
                                        <a class="btn-add btn-sm-add" @click="addLogic('logicJumps')"><i></i>新增逻辑</a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="logic_jump" v-if="detial.modelType == 'checked' || detial.modelType == 'imagesChecked'">
                    <div class="jump_item" v-for="(item,idx) in detial.logicJumps" :key="'jump'+idx">
                        <div class="jump_item_top cl">
                            <span class="fl">如果本题选中</span>
                            <div class="change_warp fr">
                                <a class="change_num reduce_btn" href="javascript: void(0);" @click="delLogic('logicJumps',idx)"></a>
                                <a class="change_num add_btn" href="javascript: void(0);" @click="addLogic('logicJumps',idx)"></a>
                            </div>
                        </div>
                        <el-checkbox-group class="jump_item_cont cl" v-model="item.logicOptionArray" v-if="detial.modelType == 'imagesChecked'">
                            <el-checkbox v-for="(option,i) in detial.topicOption" :key="i" :label="option.textKey">{{option.textVal}}</el-checkbox>
                        </el-checkbox-group>
                        <el-checkbox-group class="jump_item_cont cl" v-model="item.logicOptionArray" v-else>
                            <el-checkbox v-for="(option,i) in detial.topicOption" :key="i" :label="option.textKey">{{option.textVal}}</el-checkbox>
                        </el-checkbox-group>
                        <div class="jump_item_bot">
                            <span>则跳转到</span>
                            <el-select class="option_select" v-model="item.logicTopic">
                                <el-option label="" value="请选择题目"></el-option>
                                <el-option v-for="(option,i) in quesList" :key="i" :label="option.topicName" :value="option.uuid"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="btn-wrapper">
                        <a class="btn-add btn-sm-add" @click="addLogic('logicJumps')"><i></i>新增逻辑</a>
                    </div>
                </div>
            </template>
            <!-- 显示逻辑 -->
            <div class="logic_show" v-if="logicType == 2">
                <p class="logic_tips">共 <span class="logic_show_count">{{detial.showLogic?detial.showLogic.length:0}}</span> 条显示逻辑</p>
                <div class="display_title">
                    <label>符合以下 </label>
                    <el-select name="display" class="option_select" v-model="detial.showLogicCondition">
                        <el-option :value="1" label="全部"></el-option>
                        <el-option :value="2" label="任一"></el-option>
                    </el-select>
                    <label> 条件时显示此问题：</label>
                </div>
                <table class="jump_single_table">
                    <tbody>
                        <tr class="logicShowTr" v-for="(item,idx) in detial.showLogic" :key="'show'+idx">
                            <td>
                                <el-select class="option_select" v-model="item.logicTopic" @change="changeType(idx,item)">
                                    <el-option value='' label="请选择题目"></el-option>
                                    <el-option v-for="(option,i) in prevList" :key="i" :label="option.topicName" :value="option.uuid"></el-option>
                                </el-select>
                            </td>
                            <td>
                                <el-select class="option_select" v-model="item.logicCondition" v-if="item.quesItem.modelType == 'scale'||item.quesItem.modelType == 'radio'||item.quesItem.modelType == 'select'||item.quesItem.modelType == 'imagesRadio'||item.quesItem.modelType == 'imagesChecked'">
                                    <el-option value="" label='请选择条件'></el-option>
                                    <el-option value="1" label="选中"></el-option>
                                    <el-option value="2" label="未选中"></el-option>
                                    <el-option value="3" label="显示"></el-option>
                                    <el-option value="4" label="未显示"></el-option>
                                </el-select>
                                <el-select class="option_select" v-model="item.logicCondition" v-else>
                                    <el-option value="" label='请选择条件'></el-option>
                                    <el-option value="3" label="显示"></el-option>
                                    <el-option value="4" label="未显示"></el-option>
                                </el-select>
                            </td>
                            <td v-if="item.logicCondition == 3 || item.logicCondition == 4"></td>
                            <template v-else>
                                <td v-if="item.quesItem.modelType == 'scale'">
                                    <el-select class="option_select score_select" v-model="item.logicStart">
                                        <el-option value='' label="请选择"></el-option>
                                        <el-option v-for="(count,i) in Number(item.quesItem.scale_scope)" :key="i" :label="(i+Number(item.quesItem.scale_start))+'分'" :value="i+Number(item.quesItem.scale_start)"></el-option>
                                    </el-select>
                                至
                                    <el-select class="option_select score_select" v-model="item.logicEnd">
                                        <el-option value='' label="请选择"></el-option>
                                        <template v-for="(count,i) in Number(item.quesItem.scale_scope)" >
                                        <el-option :label="(i+Number(item.quesItem.scale_start))+'分'" :key="i" :value="i+Number(item.quesItem.scale_start)" v-if="item.logicStart && item.logicStart<(i+Number(item.quesItem.scale_start))"></el-option>
                                        </template>
                                    </el-select>
                                </td>
                                <td v-else-if="item.quesItem.modelType == 'imagesRadio ' || item.quesItem.modelType == 'imagesChecked'">
                                    <el-select class="option_select" v-model="item.logicOption">
                                        <el-option value='' label="请选择选项"></el-option>
                                        <el-option v-for="(option,i) in item.quesItem.topicOption" :key="i" :label="option.textVal" :value="option.textKey"></el-option>
                                    </el-select>
                                </td>
                                <td v-else>
                                    <el-select class="option_select" v-model="item.logicOption">
                                        <el-option value='' label="请选择选项"></el-option>
                                        <el-option v-for="(option,i) in item.quesItem.topicOption" :key="i" :label="option.textVal" :value="option.textKey"></el-option>
                                    </el-select>
                                </td>
                            </template>
                            <td>
                                <div class="change_warp">		
                                    <a class="change_num reduce_btn" href="javascript: void(0);" @click="delLogic('logicShow',idx);"></a>
                                    <a class="change_num add_btn" href="javascript: void(0);" @click="addLogic('logicShow',idx);"></a>
                                </div>
                            </td>
                        </tr>
                        <tr class="btn-wrapper">
                            <td colspan="4" width="100%">
                                <div>
                                <a class="btn-add btn-sm-add" @click="addLogic('logicShow');"><i></i>新增逻辑</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelLogic">取 消</el-button>
            <el-button type="primary" @click="saveLogic">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>
// import $ from "jquery";
export default {

	props: {
		detail: {
			type: Object,
		},
        list: {
			type: Array,
		},
        index:{
            type:Number,
        }
	},
    emits:['save-logic','close-logic'],
    data(){
		return {
            detial:JSON.parse(JSON.stringify(this.detail)),
            logicType:1,
		}
	},
    created(){

	},
    computed:{
        quesList:function(){
            let quesList = [];
            if(this.detial.logicDialog){
                this.list.forEach((item,i) => {
                    if((item.modelType != 'hr' && item.modelType != 'text') && i>this.index){
                        quesList.push(item)
                    }
                })
            }
            return quesList
            
		},
        prevList:function(){
            let prevList = [];
            if(this.detial.logicDialog){
                this.list.forEach((item,i) => {
                    if((item.modelType != 'hr' && item.modelType != 'text') && i<this.index){
                        prevList.push(item)
                    }
                })
            }
			return prevList
		},
    },
	watch: {
		detail: {
                handler:function () {
                    this.detial = JSON.parse(JSON.stringify(this.detail));
                },
                // immediate: true,
                // 深度监听 属性的变化
                deep: true
            },
        quesList:{
            handler:function () {
                if(this.quesList.length == 0){
                    this.logicType = 2;
                }else{
                    this.logicType = 1;
                }
                    
            }, 
            immediate: true, 
            deep: true
        }    

	},
    methods:{
		cancelLogic(){
			this.detial.logicDialog = false;
            this.$emit('close-logic',false)
		},
		// 保存逻辑
		saveLogic(){
			var modelType = this.detial.modelType;			//题目类型
			//跳转逻辑数据
			var skipLogicArray = new Array();
			var errType = 0;
			//判断当前题是否是复选题
            if(modelType != "checked" && modelType != "imagesChecked"){
                this.detial.logicJumps.forEach(item=>{
                    if(this.judgelogicOption(skipLogicArray, item.logicOption, item.logicStart, item.logicEnd, modelType)){
                        errType = 1;
                        return;
                    }
                    if(modelType == "scale"){
                        if(item.logicStart == '' || item.logicEnd == ''){
                            errType = 2;
                            return;
                        }
                    }else{
                        if(item.logicOption == ''){
                            errType = 2;
                            return;
                        }
                    }
                    if(item.logicTopic == ''){
                        errType = 2;
                        return;
                    }
                    skipLogicArray[skipLogicArray.length] = item;
                    // logicCount = logicCount +1;
                })
            }else{
                this.detial.logicJumps.forEach(item=>{
                    if(item.logicOptionArray.length == 0){
                        errType = 2;
                        return;
                    }
                    if(item.logicTopic == ''){
                        errType = 2;
                        return;
                    }
                    if(this.judgelogicOptionArray(skipLogicArray, item.logicOptionArray)){
                        errType = 1;
                        return;
                    }
                    skipLogicArray[skipLogicArray.length] = item;
                    // logicCount = logicCount +1;
                })
            }
            if(errType == 1){
                this.$message.error("跳转逻辑选项不能重复");
                return;
            }
            if(errType == 2){
                this.$message.error("请将跳转逻辑填写完整");
                return;
            }
            //显示逻辑数据
            var showLogicArray = new Array();
            this.detial.showLogic.forEach(item=>{
                if(item.logicTopic == ''){
                    errType = 3;
                    return;
                }
                if(item.logicCondition == ''){
                    errType = 3;
                    return;
                }
                if(item.quesItem.modelType == "scale"){
                    if((item.logicStart == '' || item.logicEnd == '') && item.logicCondition != 3 && item.logicCondition != 4){
                        errType = 3;
                        return;
                    }
                }else{
                    if(item.logicOption == '' && item.logicCondition != 3 && item.logicCondition != 4){
                        errType = 3;
                        return;
                    }
                }
                showLogicArray[showLogicArray.length] = item;
                // logicCount = logicCount +1;
            })
            if(errType == 3){
                this.$message.error("请将显示逻辑填写完整");
                return;
            }
			this.detial.logicDialog = false;
            this.$emit('save-logic',this.detial)
		},
		judgelogicOption(array, option, start, end, modelType){
            var count = 0;
            for(var i = 0; i< array.length; i++){
                var pd = array[i];
                if(modelType == "scale"){
                    if((pd.logicStart < start && start < pd.logicEnd) || 
                            (pd.logicStart < end && end < pd.logicEnd) || 
                            (pd.logicStart < start && end < pd.logicEnd)){
                        count = 1;
                    }
                }else{
                    if(pd.logicOption == option){
                        count = 1;
                    }
                }
            }
            if(count == 1){
                return true;
            }else{
                return false;
            }
        },
        judgelogicOptionArray(array, optionArray){
            var count = 0;
            for(var i = 0; i< array.length; i++){
                var pd = array[i];
                    if(JSON.stringify(pd.logicOptionArray) == JSON.stringify(optionArray)){
                        count = 1;
                    }
            }
            if(count == 1){
                return true;
            }else{
                return false;
            }
        },
        changeType(i,o){
            this.prevList.forEach(item=>{
                if(item.uuid == o.logicTopic){
                    o.quesItem = item
                    this.detial.showLogic[i] = o;
                }
            })
        },
		//逻辑切换
		logicSwitcher(logicType){
			this.logicType = logicType;
		},
        // 新增逻辑
        addLogic(type,index){
           let option = {}
			if(type == 'logicJumps'){
				option = {
							logicOption:'',
							logicStart:'',
							logicEnd:'',
							logicTopic:'',
							logicOptionArray:[]
						}
                        if(index){
                            this.detial.logicJumps.splice(index,0,option)
                        }else{
                            this.detial.logicJumps.push(option)
                        }
				
			}else if(type == 'logicShow'){
                option = {
							logicTopic:'',
							quesItem:'',
							logicCondition:'',
							logicStart:'',
							logicEnd:'',
							logicOption:''
						}
                        if(index){
                            this.detial.showLogic.splice(index,0,option)
                        }else{
                            this.detial.showLogic.push(option)
                        }
				
            } 
        },
        //删除逻辑
        delLogic(type,index){
			if(type == 'logicJumps'){
                this.detial.logicJumps.splice(index,1)
				
			}else if(type == 'logicShow'){
                this.detial.showLogic.splice(index,1)
            } 
        }
    }
};
</script>
<style lang="less" scoped>

	/deep/ .el-dialog{
		display: flex;
        flex-direction: column;
		width:605px;
		height: 439px;
        .el-dialog__header{
            border-bottom: 1px solid #eee;
            background: #f8f8f8;
        }
        .el-dialog__body{
            flex: 1;
            overflow-y: auto;
        }
		.table_nav{
			li{
				float: left;
				a{
					display: block;
					margin: 0 25px 10px 0;
					font-size: 16px;
					line-height: 30px;
					border-bottom: 2px solid transparent;
					&:hover{
						color: #0cf;
						border-bottom-color: #0cf;
					}
				}

			}
			.active{
				a{
					color: #0cf;
					border-bottom-color: #0cf;
				}
			}
		} 
		.logic_warp{
			position: relative;
			.logic_tips{
				color: #999;
				line-height: 32px;
				position: absolute;
				right:0;
				top:-42px;
			}
			.jump_single_table{
				width: 100%;
				min-height: 46px;
				tr{
					height: 46px;
					line-height: 46px;
				}
			}
            .option_select{
                width: 120px;
                .el-input__inner{
                    height: 30px;
                    line-height: 30px;
                }
                .el-select__caret{
                    line-height: 30px;
                    display: block;
                }
            }
            .score_select{
                width: 90px;
            }
            .change_warp{
                width: 60px;
                .change_num{
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url(../assets/images/form-icons.png) no-repeat;
                    vertical-align: middle;
                }
                .reduce_btn{background-position: -107px -81px;}
                .reduce_btn:hover{background-position: -107px -105px;}
                .add_btn{background-position: -137px -81px;}
                .add_btn:hover{background-position: -137px -105px;}
            }
            .btn-wrapper{
                .btn-add{
                    display:block;
                    line-height:44px;
                    border-radius: 4px;
                    font-size: 14px;
                    cursor: pointer;
                    height:44px;
                    background-color: #f8f8f8;
                    color: #999;
                    border:1px dashed #e5e5e5;
                    text-align: center;
                    margin-top: 10px;
                    &:hover{
                        border-color: #0cf;
                        color:#0cf;
                    }
                    i{
                        width:14px;
                        height:24px;
                        margin-right: 5px;
                        font-size: 28px;
                        line-height: 21px;
                        font-family: "宋体";
                        display: inline-block;
                        vertical-align: middle;
                        &:before{
                            content:"+";
                        }
                    }
                }
            }
			.logic_jump{

			}
			.logic_show{
				.display_title{
					line-height: 46px;
					color: #484848;
					font-size: 14px;
				}
			}
		}
	}

.jump_item{padding: 0 0 10px;margin: 0 0 10px;border-bottom: 1px solid #ddd;}
.jump_item:last-child{border-bottom: none;padding: 0;}

.jump_item_top{line-height: 24px;}
.jump_item_cont{margin: 10px 0;padding: 10px 20px;border: 1px solid #ddd;}
.jump_item_cont li{float: left;width: 23%;height: 24px;line-height: 24px;padding: 0 2% 0 0;margin: 5px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
// .jump_item_cont li img{display: inline-block;width: 17px;height: 15px;padding: 8px 0;margin: 0 3px;box-sizing: border-box;background: url(/form/admin/img/form-icons.png) no-repeat -116px -55px;cursor: default;}
.form_ztips{font-size: 12px;color: #f00;}
.form_ztips i{display: inline-block;width: 10px;height: 10px;border: 1px solid #ccc;margin: 0 3px;vertical-align: -2px;}

</style>