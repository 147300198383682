<template>
	<div class="field-mitem text_item special_item" type="text" :id="detial.uuid">
        <!-- 纯文本 -->
        <p>{{detial.textInput}}</p>
	</div>
</template>
<script>
import $ from "jquery";
export default {
	props: {
		detial: {
			type: Object,
		},
        index: {
            type:Number
        },
        globalItemStyle: {
			type: Object,
		},
		logicFlag:{
			type:Boolean
		},
		list:{
			type:Array
		}
	},
	computed: {
		watchAttr() {
			return {
				...this.detial.inputStyle,
				options: this.detial.options,
			};
		},
	},
	watch: {
		watchAttr: {
            handler() {
				this.$nextTick(function () {
                    $("#" + this.detial.uuid ).css({
                        display: "block",
                        margin: 0
                    });
				});
			},
			deep: true,
			immediate: true,
		},
	},
    methods:{
        
    }
};
</script>
<style lang="less" scoped>
.field-mitem{
    padding: 0 10px 10px;
    cursor: move;
    position: relative;
    overflow: hidden;
}

.field-mitem.text_item{
    min-height:84px;
    line-height: 36px;
}
.field-mitem.hr{
    min-height: 0;
    line-height: 36px;
}

</style>